import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/font.css';
import store from './store/index';
//视频边缓冲边播放
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
//点击播放-视频
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
//语言切换
import ElementLocale from 'element-ui/lib/locale';
import vueI18n from 'vue-i18n';
Vue.use(vueI18n);
import zh from './utils/i18n/zh';
import en from './utils/i18n/en';
console.log(document.body.clientWidth)
Vue.config.productionTip = false;
Vue.use(ElementUI);
// ------------读取浏览器语言
let lang = localStorage.getItem('locale');
if (!lang) {
  lang = navigator.systemLanguage
    ? navigator.systemLanguage
    : navigator.language;
  lang = lang.substr(0, 2);
}

// 配置多语言，引入element-ui多语言
const i18n = new vueI18n({
  silentTranslationWarn: true,
  locale: lang,//网站语言-初设
  messages: {
    en: {
      ...en,// 英文语言包
    },
    zh: {
      ...zh,// 中文语言包
    },
  }
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

const list = Object.keys(i18n.messages);//返回一个字符串
if (list.indexOf(lang) !== -1) {//检索字符串首次出现的位置
  if(lang === 'en'){
    store.commit('setEn', true);
  }
  if(lang === 'zh'){
    store.commit('setEn', false);
  }
  store.commit('setLocale', lang);//修改--全局属性（locale）-（当前语言）--的值

}

//拦截守卫
router.beforeEach((to, from, next) => {
	if (to.meta) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content',i18n.t("keywords_cont"))
		head[0].appendChild(meta)
	}
	next()
});
//es6兼容
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()     

//动态设置meta标签属性
import Meta from 'vue-meta';
// 使用vue-meta
Vue.use(Meta);





new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
