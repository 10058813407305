module.exports = {
  //公共-头部-主导航
  keywords_cont:"易马达 、易马达科技、易马达e换电、e换电、换电、两轮换电、电动车换电、两轮电动车换电",
  m_project_name:'官网',
  nav_1: '首页',
  nav_2: '换电产品',
  nav_3: '软件系统',
  nav_4: '公司介绍',
  nav_5: '联系我们',
  //公共-头部-控制台
  nav_6:"控制台", 
  //公共-底部-第1列子导航
  nav_2_1: '产品特性',
  nav_2_2: '产品型号',
  nav_2_3: '应用场景',
  //公共-底部-第2列子导航
  nav_3_1: '系统架构',
  nav_3_2: '平台服务',
  nav_3_3: '应用展示',
  //公共-底部-第3列导航
  nav_4_1:"公司简介",
  nav_4_2:"股东背景",
  //公共-底部-第4列导航
  nav_5_1:"合作联系",
  nav_5_2:"联系方式",
  //公共-底部--地址和电话
  company:"深圳壹换电科技有限公司",
  adds:"深圳市前海大道前海嘉里商务中心四期T1写字楼12楼",
  phone:"400-800-9693",
  wechat:"微信公众号",
  //公共-按钮
  btn_1:"查看详情 >",
  btn_2:"联系合作",
  hh17_1:"壹换电致力于",
  hh17_2:"以卓越的产品与服务，助力客户质效提升",
  
  
  
  //首页-banner-文案
  vedio_h1:"随时随地 无限续航",
  vedio_p1:"创新、开放、互利，为高效换电保驾护航",
  download:"下载壹换电App",
  //首页-主标题
  hh12_1:"多年技术沉淀，数亿次换电服务",
  hh13_1:"安全智能、多型适配的换电产品",
  hh14_1:"性能强大、安全稳定的软件系统",
  hh14_2:"AI大数据支撑，多年技术积累及市场运营实战经验，沉淀出覆盖二轮、三轮和轻型四轮电动车全场景服务生态的系统化解决方案。",
  hh15_1:"面向多种业务场景的全栈解决方案",
  hh15_2:"全场景生态布局，一站式解决换电、充电、租车、零售、融资、资产管理等场景。",
  hh16:"选择壹换电的四大理由",
  hh16_1:"核心团队",
  hh16_2:"国际视野",
  hh16_3:"全生态产品",
  hh16_4:"行业前瞻",
  hh16_p_1:"两轮车换电行业核心的员工团队，丰富的换电行业研发及运营管理经验。",
  hh16_p_2:"拥有中国大陆及海外地区多个国家换电行业经验，具备业务出海能力。",
  hh16_p_3:"产品覆盖两轮电动车销售、租车、换电、维修全业务生态，满足商户的全部需求。支持二轮、三轮和轻型四轮电动车换电经营。",
  hh16_p_4:"两轮换电行业领航者，开拓换电模式，完成用户教育，积极投身软硬件升级改造，完成大锂电产品的投入及升级。",
  hh18:"合作流程",

  // 首页-内容5
  hh5_con_1:"充电",
  hh5_con_2:"换电",
  hh5_con_3:"租车",
  hh5_con_4:"零售",
  hh5_con_5:"融资",
  hh5_con_6:"资产管理",

  //首页-副标题
  hh2_1:"行业技术积累",
  hh2_2:"换电服务次数",
  hh2_3:"系统平稳运行",
  hh2_4:"换电服务人数",
  hh2_1_1:"8",
  hh2_1_2:"5",
  hh2_1_3:"2400",
  hh2_1_4:"720",
  hh2_2_1:"年",
  hh2_2_2:"亿次+",
  hh2_2_3:"天+",
  hh2_2_4:"万+",
  hh3_tab_1:"智能换电柜",
  hh3_tab_2:"智能电池",
  hh3_btn:"了解更多",
  hh3_con_t1:"多种电柜规格，灵活适配",
  hh3_con_d1_1:"水浸测试与保护，高温预警与保护，双重散热",
  hh3_con_d1_2:"烟雾/温度传感，浪涌保护器",
  hh3_con_d1_3:"精准电池定位跟踪，防水设计",
  hh3_con_d1_4:"BMS检测与数据监控，支持多组合灵活软并柜，支持满仓换电及应急换电",
  hh3_con_d1_5:"支持离线蓝牙换电，支持远程OTA升级，电表可视化",
  hh3_con_d1_6:"高效智能的弹仓取电策略，前维护模块化及轻量化设计，助力精细化运维",
  hh3_con_t2:"多种电池型号，安全智能",
  hh3_con_d2_1:"模组自动激光焊接，整体IP67防水等级，铝制外壳双重散热",
  hh3_con_d2_2:"-20℃-60℃均可放电，智能温控保护，灌胶工艺防震/散热，连接器侧放防淋雨设计",
  hh3_con_d2_3:"国内一流电芯，循环寿命1200次+，持续放电1.5C，电池尺寸适配95%的车型",
  hh3_con_d2_4:"自带蓝牙传输，与用户实时同步数据，低电量提醒提供更优用户体验，提升用户信赖和粘性",
  hh3_con_d2_5:"电池智能云平台管理，大数据智能化管理，动态监控，随时掌握数据",
  hh3_con_d2_6:"GPS不间断实时定位，配备远程可控蜂鸣器，高精度 SOC/SOH 支持",




  // 换电产品文案
  // 产品特性
  product_c_2_h1:"产品特性",
  product_c_3_h1:"产品型号",
  product_c_3_t1:"换电柜",
  product_c_3_t2:"智能电池",
  product_c_3_tab1_1:"5仓换电柜 ",
  product_c_3_tab1_2:"6仓换电柜 ",
  product_c_3_tab1_3:"15仓换电柜",
  product_c_3_tab1_4:"18仓换电柜",
  product_c_3_tab2_1:"60V35Ah",
  product_c_3_tab2_2:"60V60Ah",
  product_c_3_tab2_3:"48V35Ah",
  product_c_3_tab2_4:"48V60Ah",
  // 多元化应用场景
  product_c_4_h1:"多元化应用场景",
  product_c_4_t1:"电动车行",
  product_c_4_t2:"外卖/快递配送站",
  product_c_4_t3:"商场",
  product_c_4_t4:"街道商铺",
  product_c_4_t5:"户外",
  product_c_4_t6:"居住小区",


  // 软件系统文案
  // 标题
  soft_h_1:"壹换电智慧云全栈解决方案",
  soft_h_6:"贴心便捷的用户App",
  soft_h_7:"安全、高效、稳定的管理工具",
  // 内容
  soft_p_1:"面向多种业务场景，提供整套技术解决方案，助力客户降本增效",
  soft_p_6:"减少用户等待和焦虑，让骑行续航无忧",
  soft_p_7:"为合作伙伴的换电数字化管理保驾护航",
  soft_con_h1:"壹换电助理",
  soft_con_h2:"壹换电智慧云管理后台",
  soft_con_h3:"BI 平台",
  soft_con_p1:"手机端运维管理工具，设备监控、运维工具轻松在手。",
  soft_con_p2:"管理数据看板、业务管理、设备管理、运营活动等，实现不同节点角色分工协作。",
  soft_con_p3:"大数据监控后台，数据安全、实时监控、异常预警。",
  



  //加入我们-banner-文案
  join_h1_1:'开放互利',
  join_h1_2:'合作共赢',
  join_h1_3:'壹换电始终保持着开放的态度，秉持互利共赢的原则，以卓越的产品',
  join_h1_4:'与服务，助力客户质效提升，为生态和行业伙伴赋能。',
  //加入我们-联系方式-主标题
  join_h2_1:'联系方式',
  //加入我们-联系方式-内容
  join_con_1:'地址',
  join_con_2:'深圳市南山区听海大道5056号前海鸿荣源中心A座',
  join_con_3:'电话',
  


  //智能换电柜-banner-文案
  zh1_1:'随时随地 无限续航',
  zh1_2:'为两轮电动车出行用户提供方便、快捷、安全的智能换电服务，完美解决充电时间过久、不易找地方充电以及充电不安全等痛点，实现随时随地无限续航',
  //智能换电柜-主标题
  zh2_1:'换电柜24小时无人值守，独立软件自动识别、动态调节充电曲线，可远程管理',
  zh2_2:'电柜类型',
  zh2_3:'意外断电有保障 备电功能首加持',
  zh2_4:'创新双防火系统',
  zh2_5:'超能五防品质保证 超强安全感',
  zh2_6:'全面的智能安全管理',
  //智能换电柜-第1屏-副标题
  zh3_1:"户外集中充电",
  zh3_2:"智能化管理",
  zh3_3:"数据实时",
  zh3_4:"收集和反馈",
  zh3_5:"扫码",
  zh3_6:"自助换电",
  //智能换电柜-第2屏-副标题
  zh3_8:"防火",
  zh3_9:"防盗",
  zh3_10:"自动恒温系统",
  zh3_11:"监控",
  zh3_12:"GPS定位",
  zh3_13:"CAN通讯",
  //智能换电柜-电柜类型-副标题
  zh3_14:"一代智能换电柜",
  zh3_15:"超能换电柜",
  zh3_16:"Mini换电柜",
  //智能换电柜-第4屏-副标题
  zh3_17:"防水",
  zh3_18:"防火",
  zh3_19:"防爆",
  zh3_20:"防雷",
  zh3_21:"防尘",
  //智能换电柜-全面的智能安全-副标题
  zh3_22:"用电防护管理",
  zh3_23:'电池健康状态\n智能诊断',
  zh3_24:'24小时动态\n充放电管理',
  zh3_25:'异常判断\n智能感应',
  zh3_26:'创新加热/降温\n循环系统',
  //智能换电柜-第2屏-正文段落
  zp_1:'自带智能水冷灭火功能；内置气溶胶灭火器；自带消防按键',
  zp_2:'升级防盗功能，侧面增加防盗锁',
  zp_3:'升级电源散热；扩大进出风口，升级机柜散热功能',
  zp_4:'24h实时监控',
  zp_5:'精准GPS定位功能',
  zp_6:'采用CAN通讯，性能显著提升',
  //智能换电柜-电柜类型-正文段落
  zp_7:'具有防爆防火等多重安全设计\n机柜可根据温度自动散热或加热\n支持离线换电，支持24小时实时监控',
  zp_8:'大功率充电，电池充电时长更短\n双重防火系统，安全性能更高\n具有备电功能，机柜断电后依然可换电\n全新防盗设计，有效解决电池被盗问题',
  zp_9:'7口智能换电柜，重量轻，易于安装维护\n整机功率小，适应更多安装环境',
  //超级电池-banner-文案
  ch1_1:'新一代超级电池',
  ch1_2:'采用智能芯片，可实现实时云端互联及数字化自我诊断技术的智能电池，多种安全保障、大容量设计告别里程焦虑，同时支持软硬连接，可适配90%以上车型',
  //超级电池-主标题
  ch2_1:'智能化软件管理',
  ch2_2:'电池类型',
  ch2_3:'采用智能芯片',
  ch2_4:'众多专利技术、多项安全认证',
  ch2_5:'创新灌胶工艺',
  ch2_6:'多重防护更加安全安心',
  ch2_7:'软连接\n实现3秒换电',
  ch2_9:'品质把控 智能制造',
  //超级电池-智能化软件管理-副标题
  ch3_1:'内置微处理器',
  ch3_2:'电池生命周期管理',
  ch3_3:'ID认证按需供电',
  ch3_4:'NFC通讯',
  //超级电池-电池类型-副标题
  ch3_5:'超能S',
  ch3_6:'锂电池',
  ch3_7:'超能X',
  ch3_8:'锂电池',
  ch3_9:'超能Y',
  ch3_10:'磷酸铁锂电池',
  //超级电池-众多专利技术、多项安全认证-副标题
  ch3_11:'前沿设计',
  ch3_12:'技术创新',
  ch3_13:'品质保证',
  //超级电池-多重防护更加安全安心-副标题
  ch3_14:'安全认证',
  ch3_15:'安全预警',
  ch3_16:'实时监控',
  ch3_17:'数据互联',
  ch3_18:'结构保护',
  //超级电池-电池类型-正文段落
  cp_1:'采用全新灌胶工艺，安全性能更高\n大容量设计，续航能力更强\n标准化模块，动力强劲\n同时支持软硬连接，适配多种车型',
  cp_2:'完全匹配新国标要求，适配各类国标车型\n全新模块设计，电池更智能\n高低温性能优异，寿命长外观精致\n人体工学设计，方便取放',
  cp_3:'磷酸铁锂锂离子电池\n安全性能高\n寿命长，高温性能好\n容量大，重量轻',
  //超级电池-采用智能芯片-正文段落
  cp_4:"数字化自我诊断技术\n智能微处理芯片统一管理\n专有协议，云端大数据实时互联\n异常状态告警，自我诊断，远程关闭\n单电池触发更换，可二次利用",
  //超级电池-众多专利技术、多项安全认证-正文段落
  cp_9:'系统化换电网络\n全产业链条管理\n最新电池智能串并联技术\n电池模块化、标准化设计',
  cp_10:'自主研发云计算系统\n实时信息采集及智能分析处理\n每日150GB数据流量\n先进BMS、PMS电控技术\n优秀产品安全性及性能表现\n自主研发智能芯片\n全生命周期电池管理',
  cp_11:'产品设计与创新工艺完美结合\n独立优质生产线\n汽车级电芯技术\nIP66等级电池PACK技术\n无忧户外使用',
  //超级电池-标题段落
  cp_16:'创新电芯灌胶工艺，安全有保障',
  
  cp_17:'连接更可靠，门槛成本双降低',
  
  cp_18:'自动化电池生产线',
  //充电桩-banner-文案
  ph1_1:'易马达e充电',
  ph1_2:'城市级电单车智慧管理解决方案',
  //充电桩-主标题
  ph2_1:"易马达e充电",
  ph2_2:"超十重防护 安全有保障",
  //充电桩-易马达e充电-副标题
  ph3_1:'标准插口',
  ph3_2:'三重保护',
  ph3_3:'微信付费',
  ph3_4:'4G通讯',
  ph3_5:'三重防护',
  ph3_6:'多平台对接',
  //充电桩-超十重防护 安全有保障-副标题
  ph4_1:'断电记忆保护',
  ph4_2:'短路保护',
  ph4_3:'倾倒保护',
  ph4_4:'整机过载预警保护',
  ph4_5:'充电站过温保护',
  ph4_6:'过载保护',
  ph4_7:'漏电保护',
  ph4_8:'输入过/欠压保护',
  ph4_9:'消防预警',
  ph4_10:'防蹭电保护',
  ph4_11:'空载无电',
  ph4_12:'防盗电保护',
  //充电桩-项目-副标题
  ph5_1:'安装方式',
  ph5_2:'外壳材质',
  ph5_3:'总功率',
  ph5_4:'输入电压 (V)',
  ph5_5:'输入频率（Hz）',
  ph5_6:'单路最大功率( W)',
  ph5_7:'单路最大输出电流 (A)',
  ph5_8:'待机功耗',
  ph5_9:'计量精度',
  ph5_10:'工作温度',
  ph5_12:'工作湿度',
  ph5_13:'工作海拔',
  ph5_14:'防等级',
  ph5_15:'噪声控制',
  ph5_16:'MTBF(h)',
  //充电桩-内容-副标题
  ph6_1:'壁挂 /落地',
  ph6_2:'PVC 标贴、阻燃镀锌板',
  ph6_3:'7KW (32A)',
  ph6_4:'AC220 ± 20%',
  ph6_5:'50± 10%',
  ph6_6:'600W(700W保护 )',
  ph6_7:'3.5 A',
  ph6_8:'≤2.0W',
  ph6_9:'± 1.0% /0.02元',
  ph6_10:'-20°C~60°C',
  ph6_12:'5%~95%',
  ph6_13:'2000m',
  ph6_14:'IP56',
  ph6_15:'≤60dB',
  ph6_16:'10 万',
  //充电桩-表格-标题
  ph7_1:'产品',
  ph7_2:'项目',
  ph7_3:'内容',
  //充电桩-易马达e充电-正文段落
  pp_1:'使用国标插座及国标线槽安装',
  pp_2:'过充保护、多充保护、定时保护',
  pp_3:'微信扫码付费，分级管理',
  pp_4:'4G通讯，上报数据',
  pp_5:'防水、防火、防尘',
  pp_6:'可与消防监控中心、110监控平\n台、物业监控平台对接',
  //超级换电车-banner-文案
  bh1_1:'新国标超能智慧车',
  bh1_2:'以深度定制、智能加成引领两轮出行全新风尚',
  //超级换电车-主标题
  bh2_1:'丰富的产品与服务功能',
  bh2_2:'朋友圈 小团体 同城用多种应用场景',
  //超级换电车-副标题
  bh3_1:'一键锁车',
  bh3_2:'一键预约/导航',
  bh3_3:'一键换电',
  bh3_4:'自动驻车',
  bh3_5:'手机APP寻车',
  //e巡逻业务-banner-文案
  eh1_1:'5G智慧城市\n巡逻执法管控系统',
  //e巡逻业务-主标题
  eh2_1:"我们的社会价值",
  eh2_2:"智慧城市巡逻执法管控系统架构",
  eh2_3:"智慧城市巡逻执法管控系统特色",
  eh2_4:"大数据赋能",
  //e巡逻业务-副标题
  eh3_1:'换电车管理平台',
  eh3_2:'换电柜云平台',
  eh3_3:'5G智慧城市巡逻执法管控系统',
  eh3_4:'大数据赋能',
  eh3_5:'换电网络支持',
  eh3_6:'成都城市巡逻执法管控系统',
  eh3_7:'随时换电\n无限续航',
  eh3_8:'新型巡逻执法\n协作方式',
  eh3_9:'新型群众\n互动方式',
  eh3_10:'巡逻车辆实时\n全面监控',
  eh3_11:'随时换电，无限续航',
  eh3_12:'巡逻车辆实时全面监控',
  eh3_13:'新型巡逻执法协作方式',
  //e巡逻业务-第2，4屏-正文段落
  ep_1:'基于5G数据通道，车辆监控更加实时，视频媒体交互不存在速度瓶颈，催生新型的监控方式',
  ep_2:'基于5G数据通道，更多数据上报，监控更实时全面，响应速度更快',
  ep_3:'5G换电网络和新基建为起点，孵化出5G智慧城市巡逻执法管控系统',
  ep_4:'巡逻执法人员利用换电网络预约换电、扫码换电、半离线换电、离线换电为电动车无限增程，大大提升工作效率。',
  ep_5:'总部通过监控大厅对外面巡逻的车辆进行实时监控/互动以及对巡逻车辆进行调度。',
  ep_6:'包括：实时查看车辆位置、查看巡逻车辆轨迹、实时呼叫巡逻车辆、实时和巡逻车辆视频对接、执法工单派送、人力动态调度等',
  ep_7:'外出巡逻的巡逻执法人员可以通过手机APP进行执法协作。',
  ep_8:'包括：地图上发现附近巡逻同事、对附近同事进行呼叫、和附近的巡逻同事线上组队进行执法协作',
  ep_9:'巡逻执法人员优先换电',
  //e巡逻业务-大数据赋能-正文段落
  ep1_1:'大数据分析：',
  ep1_2:'片区巡逻情况',
  ep1_3:'片区执法工单处理情况',
  ep1_4:'片区城市管理情况',
  ep1_5:'片区巡逻人力资源消耗情况',
  ep1_6:'片区群众对执法的满意情况',
  //新零售业务-banner-文案
  nh_1:'新零售\n基于换电的全新业务模式',
  nh_3:'卖车',
  nh_4:'二手车',
  nh_5:'租车',
  nh_6:'修车',
  nh_7:'车辆保养',
  nh_8:'换电屋',
  nh_9:'车生活',
  nh_10:'骑车生活',
  nh_11:'社区运营',
  //新零售业务-主标题
  nh2_1:'丰富的服务功能',
  nh2_2:'欢电的变现能力',
  nh2_3:'新零售经营概念',
  nh2_4:'我们的社会价值',
  //新零售业务-丰富的服务功能-副标题
  nh3_1:"微信营销",
  nh3_2:"数字化海报",
  nh3_3:"远程探店",
  nh3_4:"数据分析",
  nh3_5:"直播卖货",
  nh3_6:"优惠券",
  nh3_7:"内容分销",
  nh3_8:"商品管理",
  //新零售业务-欢电的变现能力-副标题
  nh3_9:"交易提成",
  nh3_10:"内容/广告",
  nh3_11:"Saas服务",
  nh3_12:"应用市场",
  //新零售业务-新零售经营概念-副标题
  nh3_13:"智能两轮电动自行车\n研发与生产",
  nh3_15:"供应链管理\nSaas软件系统",
  nh3_17:"电子商务\n线上引流成交",
  nh3_19:"电动两轮\n自行车租售",
  nh3_21:"人资招聘\n培训服务",
  nh3_23:"日用品\n批发零售",
  //新零售业务-我们的社会价值-副标题
  nh4_1:"打造新国标智能",
  nh4_2:"两轮电动车",
  nh4_3:"电动自行车门店",
  nh4_4:"供应链管理",
  nh4_5:"即时配送行业",
  nh4_6:"人资招聘培训",
  nh4_7:"推动两轮电动自行车",
  nh4_8:"门店电子商务",
  //新零售业务-正文段落
  np_1:'打通微信生态，完美利用你的私域流量实现线上生意增长',
  np_2:'自定义分享素材，一键生成高颜值海报，高效管理的数字化推广',
  np_3:'1对1视频介绍，推荐商品、在线发券两不误，聊天中实现交易转化',
  np_4:'精准量化门店经营情况，实时追踪订单、销量、转化效果',
  np_5:'直播形式革命，支持跨门店商品联动，轻松实现批量带货',
  np_6:'商品券、门店券、活动券应有尽有，满减券、折扣券、秒杀券步步为营',
  np_7:'以内容加速生意转化，洞察新商机，实现生意增长的可度量、可归因、可优化',
  np_8:'网状管理结构，支持多门店商品联动管理，高效运营线上门店',
  np_9:'商品服务交易佣金 VIP⻔店月费',
  np_10:'品牌广告/商户微广告/内容创作\n等进行收益分成',
  np_11:'物资管理/工单服务/大数据分析\n等公共Saas工具服务费用',
  np_12:'行业特性的应用入驻 与第三方\n开发者进行收益分成',
  np_13:'新零售项目致力于推进新国标智能两轮电动车，与政府一路推进新国标电动车普及事业发展',
  np_14:'新零售项目针对合作门店提供供应链服务，提供符合国家标准电动自行车配件配件/车辆采购/门店SAAS系统进销存管理，集中采购降低门店平均采购成本',
  np_15:'帮扶企业解决就业脱贫问题，目前我国即时配送行业属于高速发展阶段，通过新零售门店实现站点配送骑手招聘问题。同时健全配送人员行业培训体系，指导骑手使用软件，了解交规法规，依法营运',
  np_16:'基于微信小程序生态，帮扶合作门店线上引流开店，促进24小时成交，借助互联网o2o红利，打通线上线下，实现创收',
  //天天租业务-banner-文案
  dh_1:"天天租解决方案",
  dh_2:"e换电凭借规模成熟的换电网络，投入精⼒及资源帮用户解决充电难的问题，同时帮助传统车行打通与用户的线上通道，在用户、车行、换电⽹络、车厂/电池厂商之间形成闭环，帮助车行做好租车卖车生意",
  //天天租业务-主标题
  dh2_1:"租车行业存在的痛点",
  dh2_2:"e换电要让天下没有难做的租车生意",
  dh2_3:"e换电天天租平台特点",
  dh2_4:"硬件产品升级",
  //天天租业务-副标题
  dh3_1:"车辆逾期",
  dh3_2:"资产丢失",
  dh3_3:"充电难",
  dh3_4:"缺乏营销",
  dh3_5:"缺乏流量",
  dh3_6:"提供租车Sass平台",
  dh3_7:"轻松管理资产",
  dh3_8:"提供强大的换电网络",
  dh3_9:"支持多种应用场景",
  dh3_10:"杜绝逾期不续费的情况发生",
  dh3_11:"支持多种营销模式",
  dh3_12:"智慧仓",
  dh3_13:"无线通信",
  dh3_14:"车辆智能管理平台",
  //天天租业务-正文段落
  dp_1:"用户租期逾期，不续费且继续使用的情况严重",
  dp_2:"车辆丢失，无法追回，资产丢失的风险大",
  dp_3:"车辆出租后面临充电难题",
  dp_4:"营销手段简陋，几近于无",
  dp_5:"缺乏线上平台引流，流量全靠地段",
  dp_6:"e换电为租车行业赋能，所有车行都能入驻e换电租车平台，不收手续费零提成",
  dp_7:"e换电配备以数据驱动的资产管理系统，帮助车行科学管理资产，智慧仓自带定位模组以及4g模组，租赁中的车辆位置一目了然",
  dp_8:"e换电拥有成熟的换电⽹网络，用户通过e换电平台租赁车辆无需担心充电问题",
  dp_9:"e换电租车平台支持按周/月/季租车，支持共享扫码短租，支持有车(闲置车辆)用户入驻，未来还将支持卖车业务",
  dp_10:"用户通过平台租赁车辆，租金到期之后，换电套餐随之失效，用户如不续费，将无法进行换电",
  dp_11:"支持芝麻信用免押、花呗分期、优惠券红包等，未来还将开拓更多推广方式，车行可免费使用丰富营销工具",
  dp_12:"智慧仓整合GPS，电池管理，防盗报警，智能检测一体",
  dp_13:"4G蓝牙，实现云平台与车辆，手机APP与车辆实时连接",
  dp_14:"对车辆进行全方位监控，OTA升级，远程寻车等",
  //天天租业务-标题段落
  dp_15:'提供多种业务场景，帮助商家吸引新用户下单、留存',
  // 分时租业务-banner-文案
  rh_1:'分时租解决方案',
  rh_2:'为临时需要用骑行出行的用户提供便捷的车辆及配套换电服务，集合租赁与服务以创新和技术引领行业，赋能社会',
  // 分时租业务-主标题
  rh2_1:'用户为什么选择分时租',
  rh2_2:'一站式营销解决方案',
  rh2_3:'分时租产品组成',
  rh2_4:'分时租产品使用流程',
  rh2_5:'分时租与共享电单车的对比',
  rh2_6:'丰富功能为您的出行保驾护航',
  // 分时租业务-第2，3屏-副标题
  rh3_1:'随时随地\n轻松租车',
  rh3_2:'一键租还\n续航无忧',
  rh3_3:'便捷出行\n智慧用车',
  rh3_4:'价格透明\n实惠放心',
  rh3_5:'景区',
  rh3_6:'写字楼',
  rh3_7:'酒店',
  rh3_8:'社区/小区',
  rh3_9:'校园',
  rh3_10:'工厂',
  // 分时租业务-分时租产品使用流程-副标题
  rh3_11:'APP/小程序\n扫码租车',
  rh3_12:'支付押金或选择\n芝麻信用免押金',
  rh3_13:'开始骑行，按时\n间进行计费',
  rh3_14:'骑行结束，根据提示寻找\n就近还车区域进行还车',
  rh3_15:'结束计费\n结束服务',
  // 分时租业务-对比-副标题
  rh3_16:'共享电单车',
  rh3_17:'分时租',
  // 分时租业务-丰富功能为您的出行保驾护航-副标题
  rh3_18:'地图寻车/',
  rh3_19:'还车',
  rh3_20:'清晰查看车辆',
  rh3_21:'详情',
  rh3_22:'扫码租车',
  rh3_23:'方便快捷',
  rh3_24:'实时监控',
  rh3_25:'精准定位',
  rh3_26:'电量提醒',
  rh3_27:'定点还车',
  rh3_28:'数据互联',
  rh3_29:'智能服务',
  // 分时租业务-对比-正文段落
  rp_1:'车型',
  rp_2:'里程限制',
  rp_3:'无限续航',
  rp_4:'电池',
  rp_5:'人工运维成本高',
  rp_6:'用户可自主换电',
  rp_7:'还车方式',
  rp_8:'地图寻找还车店',
  rp_9:'实景参考，定点还车',
  rp_10:'参与者',
  rp_11:'品牌方/大型代理',
  rp_12:'共享共赢，中小商家可参与',
  rp_13:'可拓展性',
  rp_14:'封闭系统',
  rp_15:'开放系统，可发展子经销商',
  rp_16:'使用场景',
  rp_17:'开放环境',
  rp_18:'封闭场景',
  rp_19:'目标人群',
  rp_20:'泛大众',
  rp_21:'场景内人群用车需求更迫切',
  rp_22:'车损风险',
  rp_23:'不可控',
  rp_24:'场景内使用，相对更可控',
  rp_25:'计费模式',
  rp_26:'固定计费模式',
  rp_27:'多种模式可选',
  // 分时租业务-一站式营销解决方案-正文段落
  rp2_1:'景区内骑行游览',
  rp2_2:'白领日常通勤',
  rp2_3:'酒店租车旅行',
  rp2_4:'日常出行、买菜等',
  rp2_5:'校园内短途通勤',
  rp2_6:'厂区内通勤',
  // 分时租业务-一站式营销解决方案-标题段落
  rp3_1:'提供多种业务场景，帮助商家吸引新用户下单、留存',
  //e换电业务-banner-文案
  mh_1:'全球智慧能源网络生态构建者',
  //e换电业务-主标题
  mh2_1:'丰富的产品与服务功能',
  mh2_2:'核心技术永不止步 出行领域新基建生态构建者',
  mh2_3:'换电模式适用于多种场景',
  mh2_4:'随时随地 无限续航',
  mh2_5:'3秒换电 从此无需充电',
  mh2_6:'易马达e换电 VS 传统充电方式',
  //e换电业务-丰富的产品与服务功能-副标题
  mh3_2:'预约换电',
  mh3_3:'离线换电',
  mh3_4:'地图导航',
  mh3_5:'电柜信息',
  mh3_6:'车辆信息',
  mh3_7:'电池数据',
  //e换电业务-换电模式适用于多种场景-副标题
  mh3_8:'快递外卖',
  mh3_9:'日常出行',
  mh3_10:'政府公用',
  mh3_11:'校区园区',
  mh3_12:'旅游景区',
  //e换电业务-随时随地 无限续航-副标题
  mh3_13:'60+',
  mh3_14:'8000+',
  mh3_15:'600,000+',
  mh3_16:'>1,000,000,000',
  //e换电业务-3秒换电 从此无需充电-副标题
  mh4_1:'优质电池\n无限续航',
  mh4_2:'随时随地\n3秒换电',
  mh4_3:'智能检测\n5重防护',
  mh4_4:'集中管理\n智能锂电',
  //e换电业务-对比-副标题
  mh4_5:'安全可靠',
  mh4_6:'放心无忧',
  mh4_7:'省钱',
  mh4_8:'便利体验',
  mh4_9:'省时',
  mh4_10:'安全隐患',
  mh4_11:'丢失困扰',
  mh4_12:'成本高',
  mh4_13:'充电不便',
  mh4_14:'充电慢',
  //e换电业务-表单-标题
  mh5_1:'e换电解决方案',
  mh5_2:'传统电动车充电痛点',
  //e换电业务-标题段落
  mp_1:'智能充电桩 潮范儿电车 新型换电柜 超安全电池',
  mp_2:'城市级电单车管理生态体系 ，车电分离，密布换电网，场景定制',
  mp_3:'充电到换电，创建新行业 搭建两轮车绿色能源网络',
  //e换电业务-丰富的产品与服务功能-正文段落
  mp2_1:'电池电量即将耗尽？您可通过APP提前预约电池，确保到达预约电柜后有电可换',
  mp2_2:'换电柜因网络或硬件原因断网（无法连接服务器），您仍可在离线的电柜上按照指引进行换电操作',
  mp2_3:'APP自带地图导航功能，您可使用一键导航功能前往换电柜',
  mp2_4:'电柜位置、电池充电情况、电柜营业时间、电柜中各种规格电池可换数量一目了然',
  mp2_5:'您的车辆您做主，车辆行驶轨迹、车辆定位、异动报警、一键启动、一键锁车等功能助您随手掌控车辆',
  mp2_6:'您可在“我的电池模块”随时查看电池的编号信息、电池温度、电池容量等，及时掌控电池情况',
  //e换电业务-换电模式适用于多种场景-正文段落
  mp3_1:'快递、外卖、闪送等即时配送从业人员的工作好帮手，3秒换电、持续续航，保证车辆随时待命，大幅提升跑单效率。',
  mp3_2:'居家自用出行安全方便，免除充电烦恼、避免充电安全问题，换电柜全城覆盖，随时换电，高效便捷。收费方式灵活，可按次数或包月。',
  mp3_3:'换电基础设施建设便于政府部门改善城市交通情况、提升城市服务质量，大数据平台可监控接入服务车辆的行驶轨迹、掌握行驶动态，为优化城市交通网络提供数据支持，有助城市交通管理和优化。',
  mp3_4:'为校园师生或者园区工作人员出行提供便利，大学校园或者工业园区只需安装相应换电设备即可为员工或在校职工、学生提供便捷的短途出行支持服务。',
  mp3_5:'提供灵活的换电方案以满足游客的旅途需求，游客扫码即可使用且换电车辆无需充电，续航里程有保障，一旦电量即将耗尽，导航到最近换电站即可完成换电。',
  //e换电业务-随时随地 无限续航-正文段落
  mp4_1:'城市',
  mp4_2:'一线城市全覆盖，新一线城市重点布局',
  mp4_3:'换电柜',
  mp4_4:'建立健全换电网络',
  mp4_5:'颗/天',
  mp4_6:'累计换电颗数超过3亿颗',
  mp4_7:'公里',
  mp4_8:'相当于从地球骑行到火星往返9次',
  //e换电业务-对比-正文段落
  mp5_1:'全过程集中化、可视化、标准化室外充电',
  mp5_2:'充电柜消防措施完善，杜绝火灾风险',
  mp5_3:'GPS寻回率95%+',
  mp5_4:'易马达对电池全生命周期管理、梯次利用',
  mp5_5:'电池租赁使用',
  mp5_6:'无需购买',
  mp5_7:'轻便，可单手换电、离线换电等',
  mp5_8:'扫码、刷脸，快捷方便',
  mp5_9:'无需充电，3秒轻松换电',
  mp5_10:'随时随地，想换就换',
  mp5_11:'充电过程不可控',
  mp5_12:'存在着火爆炸风险',
  mp5_13:'车辆无定位',
  mp5_14:'丢失无法找回',
  mp5_15:'用户购买电池1,500元+/颗',
  mp5_16:'需定期更换， 维护成本高',
  mp5_17:'电池笨重，充电搬取极为不便',
  mp5_18:'充电过程不可控，存在爆炸风险',
  mp5_19:'耗时2-8小时 无法应急',
  mp5_20:'每天5元+',


  //about-页面导航
  n_1:'公司简介',
  n_2:'发展历程',
  n_3:'企业文化',
  n_4:'最新资讯',

  //about-主标题
  ah_1:'简介',
  ah_2:'股东背景',

  //about-简介内容
  ah_p1:"深圳壹换电科技有限公司，是Immotor集团为响应大客户高端换电服务而专门设立的高科技企业。公司旗下拥有“壹换电”旗舰换电品牌，专注于为B端大客户、专业长续航需求骑手提供行业领先的换电服务；同时也向换电行业的新兴企业提供全套软硬件解决方案，赋能行业后起之秀快速落地业务及拓展市场。",
  ah_p2:"公司充分沿袭集团应用科技创新和商业模式创新的基因，更注重联合产业上下游共同推动产品技术开发，打造生态型供应链体系，引领行业商业模式变革。在换电网络大数据算力、超级电池PACK设计、高端智能电柜设计、快充技术研发应用、电池管理系统和柜控管理系统研发等众多领域引领行业发展。公司携全新换电体系面世，全面覆盖二、三轮和轻型四轮电动车换电需求，广泛获得专业骑手认可，并成为全球领先的物流企业能源战略合作伙伴，为其全国范围提供最领先的换电服务。",
  //about-股东背景-副标题
  ah2_1:'政府基金',
  ah2_2:'世界五百强',
  ah2_3:'国际顶尖投资机构',

  
  
  
  






//新增视频
v1_h1:"双重防火 安全保障",
v2_h1:'品质把控 智能制造',


n_ah4_1: '易马达科技完成C2轮融资，将加速换电全球化进程',
n_ah4_2: '银川市城市建设投资控股有限公司与青岛易换电新能源有限公司签订小型车辆绿色能源充换电建设项目合作协议',
n_ah4_3: '深圳易马达进入2021深圳“专精特新”中小企业名单（592号）',
n_ah4_4: '趣链科技携手易马达科技推出全球首个区块链换电SaaS平台',
n_ah4_5: '腾讯云未来社区携手易马达科技，打造绿色安全社区',
n_ah4_6: '易马达科技入选首届界面科技大会「2021 Real 100创新家」榜单',
n_nt_1: '2022-06-28',
n_nt_2: '2022-06-23',
n_nt_3: '2022-06-15',
n_nt_4: '2022-06-14',
n_nt_5: '2021-12-10',
n_nt_6: '2021-10-14',


  };
  
  
  