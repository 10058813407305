<template>
  <div class="header font-medium fz18 fw500" :class="{ 'is-fixed': isFixed,'about-en':isEn?'about-en':'','is-home':linkStyle}">
    <div class="header-md" :class="[ openBtn?'submenu_show':'submenu_hide','style2']">
      <div class="logo"  @click.stop="toHome">
        <!-- <img    v-if="isEn" :src="require('../assets/logo/logo2.png')" /> -->
        <img src="../assets/logo/logo-black.png"  />
        
      </div>
      <ul class="nav">
        <li 
        v-for="(item ,index) in mainMenu"
        :key="item.mainIndex">
          <div v-if="item.sub.length>0" @mouseover="mouseOver1(item.mainIndex)" @mouseleave="mouseLeave1(item.mainIndex)" class="menu_item relative">
            <div  class="outLi" :class="{ 'active': item.clickSign }" >{{$t(item.name)}}</div>
            <div  class="sub" :class="{ 'subMenu':item.inSign ,'sub3':index==2&&isEn}">
              <div class="sub-son" 
              v-for="sub in item.sub"
              :key="sub.name"
              @click.stop="doToggle(item.mainIndex,sub.subIndex)"  
              @mouseover="mouseOver(item.mainIndex,sub.subIndex)" 
              @mouseleave="mouseLeave(item.mainIndex,sub.subIndex)" 
              :class="{active:sub.clickSign||sub.inSign}"
              >  
                <router-link :to="sub.path">{{$t(sub.name)}}</router-link>
              </div>
            </div>  
          </div>
          <div  v-else  @click.stop="doToggle(item.mainIndex)" class="outLi" :class="{ active: item.clickSign }">
            <router-link :to="item.path2" v-if="isEn">{{$t(item.name)}}</router-link>
            <router-link :to="item.path"  v-else>{{$t(item.name)}}</router-link>            
          </div>
        </li>
      </ul>             
      <!-- <div class="lang-con" @click.stop="langClick()">
          <span>{{ langText }}</span>
          <i class="iconfont icon-imt-unfold arrow-down"></i>
          <ul class="ul-lang" v-show="langShow">
            <li
              v-for="item in langList"
              :key="item.id"
              @click.stop="toggle(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div> -->
      <div class="bg_hover"></div>   
    </div>
    <div class="header-xs">
      <div class="logo">
        <img
          src="../assets/menu.png"
          class="menu"
          @click="doToggle"
        />
        <img src="../assets/logo/logo-black.png" class="img"/>
        <div class="clear"></div>
      </div>
      <ul :class="{ active: isActive2 }">
        <li 
        v-for="item in mainMenu"
        :key="item.mainIndex">
          <div v-if="item.sub.length>0" @mouseover="mouseOver1(item.mainIndex)" @mouseleave="mouseLeave1(item.mainIndex)" class="menu_item relative">
            <div >{{$t(item.name)}}</div>
            <div  class="sub" :class="{ subMenu:item.inSign }">
              <div class="sub-son" 
              v-for="sub in item.sub"
              :key="sub.name"
              @click.stop="doToggle(sub.subIndex)"  
              >  
                <router-link :to="sub.path">{{$t(sub.name)}}</router-link>
              </div>
            </div>  
          </div>
          <div v-else  @click.stop="doToggle(item.mainIndex)">
            <router-link :to="item.path">{{$t(item.name)}}</router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eHeader',
  data() {
    return {
      //语言-s
      langList: this.$store.state.langList,//初值-拿全局语言列表
      langShow: false, // 语言下拉列表显隐
      langText: '',
      //语言-e
      mainMenu:[
        {
          name:'nav_1',
          path:'/',
          path2:'/',
          mainIndex:0,
          clickSign:false,
          sub:[],      
        },
        {
          name:'nav_2',
          path:'/product',
          path2:'/product',
          mainIndex:1,
          clickSign:false,
          sub:[],     
        },
        {
          name:'nav_3',
          path:'/soft',
          path2:'/soft',
          mainIndex:2,
          clickSign:false,
          sub:[],     
        },
        {
          path:'/about',
          path2:'/about',
          name:'nav_4',
          mainIndex:3,
          clickSign:false,
          sub:[],      
        },
        {
          path:'/join',
          path2:'/join',
          name:'nav_5',
          mainIndex:4,
          clickSign:false,
          sub:[],      
        },
      ],
      linkStyle:true,
      openBtn:false,
      isActive2: false, // 移动端菜单切换
      isFixed: false
    };
  },
  computed:{
    isEn:function(){
      return this.$store.state.isEn
    }
  },
  watch: {
    '$route' (to) {
      this.mainMenu.forEach(e => {
              e.clickSign=false
              if(e.sub.length>0){
                e.sub.forEach((s) => {
                  s.clickSign=false
                  if(s.path===to.path){     
                    s.clickSign=true
                    e.clickSign=true
                  }
                })       
              }else{
                if(e.path===to.path){
                  e.clickSign=true
                }               
              }                    
      });
      if(to.path==='/'||to.path==='/battery-exchange'){ 
        this.linkStyle =  true; 
      }else{
        this.linkStyle =  false; 
      }
    }
  },
  created() {
    const index = this.langList.findIndex(v => v.id === this.$i18n.locale);//在全局语言列表里找
    if (index !== -1) {//存在
      this.langText = this.langList[index].label;
    } else {//不存在，设置main.js里this.$i18n设定的处置
      this.langText = this.langList[0].label;
    }
    document.addEventListener('click', () => {//监听页面中所有的点击事件
      this.langShow = false;//隐藏语言下拉列表
    });
  },
  mounted() {
    console.log(this.$route.fullPath);
    // 滚动条滚动，菜单栏切换定位状态
    window.addEventListener('scroll', () => {
      this.langShow = false;
      const height =
        window.pageYOffset ||document.documentElement.scrollTop || document.body.scrollTop;
      if (height > 160) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    });
  },
  methods: {
    // 点击-当前语言
    langClick() {
      this.langShow = true;
    },
    // 切换--语言  
    toggle(data) {   
      this.$i18n.locale = data.id;
      this.langText = data.label;
      let path =this.$route.path
      if(data.id === 'en'){
        if(path == '/join'){
          this.$router.push({path: '/solutions'});
        }
        this.$store.commit('setEn', true);
      }
      if(data.id === 'zh'){
        if(path == '/solutions'||path == '/join-En3'){
          this.$router.push({path: '/join'});
        }
        this.$store.commit('setEn', false);
      }
      localStorage.setItem('locale', data.id);
      this.$store.commit('setLocale', data.id);
      document.title = this.$t('m_project_name');//设置当前页面的标题
      this.langShow = false;
    },
    toHome(){
      window.scrollTo(0,0);
      this.$router.push({ path:'/'  })
    },
    // 菜单切换
    doToggle(m,n) {
      this.isActive2 = !this.isActive2;
      window.scrollTo(0,0);
      this.mainMenu.forEach(e => {
          e.clickSign=false
          if(e.sub.length>0){
            e.sub.forEach((s) => {
              s.clickSign=false
            })       
          }                                  
        });
        if(m===0||(m===2&&n===0)){ 
          this.linkStyle =  true; //控制导航文字颜色，背景颜色等样式，true--白 -主页和换电业务页第一屏是视频要白
        } else{
          this.linkStyle =  false; //控制导航文字颜色，背景颜色等样式，false--黑
        } 
        this.mainMenu[m].clickSign = true
        if(typeof(n)!='undefined'){
            this.mainMenu[m].sub[n].clickSign = true
        }     
    },
    // 主-移入
    mouseOver1(n) {
      this.openBtn = true 
      this.mainMenu[n].inSign = true  
    },
    // 主-移出
    mouseLeave1(n) {
      this.openBtn = false
      this.mainMenu[n].inSign = false  
    },
    // 子-移入
    mouseOver(m,n) {
      this.mainMenu[m].sub[n].inSign = true
    },
    // 子-移出
    mouseLeave(m,n) {  
      this.mainMenu[m].sub[n].inSign = false 
    },
  }
};
</script>

<style lang="scss" scoped>
.header{
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  user-select:none;
}
.lang-con{
  width: 146px;
  z-index: 8;
  cursor: pointer;
  font-size:14px;
}
    .outLi {
      margin: 0 30px; 
      position: relative;
      &.active::after {
        content: '';
        height: 3px;
        background: #165DFF;
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
      }
      
    }
.submenu_hide.style1 .outLi,
.submenu_hide.style1 .lang-con,
.submenu_hide.style1 .outLi a{
  color: #fff; 
}

.is-fixed .submenu_hide.style1 .outLi,
.is-fixed .submenu_hide.style1 .lang-con,
.is-fixed .submenu_hide.style1 .outLi a,
.submenu_show.style1 .outLi,
.submenu_show.style1 .outLi a,
.style2 .outLi,
.style2 .outLi a{
  color: #30363e;
}
.style2 .outLi{
  &.active{
    color: #165DFF;
    a{
      color: #165DFF;
    }
  }
}
.ul-lang{
  font-size: 14px;
  z-index:9999;
  border-radius: 0 0 6px 6px;
  padding: 15px;
}
.ul-lang li{
  font-size: 14px;
}
.style1 .ul-lang{
  background-color: transparent;
}
.is-fixed .style1 .ul-lang,
.style2 .ul-lang{
  background: white;
}
.sub-son a{
    color: rgba(22,25,60,0.5);
} 
.sub-son.active a{
    color: rgba(22,25,60,1);
  }

.submenu_show .bg_hover{ 
  display:block; 
  width: 100%; 
  height: 60px; 
  background:rgba(255,255,255,1); 
  z-index:7; 
  position: absolute; 
  top: 0px; 
  left: 0px;
  animation:a_bg_hover_show 0.2s ease-out both; 
  -webkit-animation:a_bg_hover_show 0.2s ease-out both; 
  transform-origin:50% 0%;
  -webkit-transform-origin:50% 0%;
  }
.submenu_hide .bg_hover{ 
  position: absolute; 
  top: 0px; 
  left: 0px;
  animation:a_bg_hover_hide 0.15s ease-out both;
  -webkit-animation:a_bg_hover_hide 0.15s ease-out both; 
  transform-origin:50% 0%;
  -webkit-transform-origin:50% 0%;
}
@keyframes a_bg_hover_show {
	0%{ -webkit-transform:scale(1,0.5); opacity: 0;}
	100%{  -webkit-transform:scale(1,1); opacity: 1;}
}
@keyframes a_bg_hover_hide {
	0%{ -webkit-transform:scale(1,1); opacity: 1;}
	100%{  -webkit-transform:scale(1,0.5); opacity: 0;}
}
.sub{
  display: none;
  box-sizing: border-box;
  position: absolute;
  width:146px;
  top: 60px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 8;
  text-align: center;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0 ,0.1);
  transition: all .2s;
  padding-bottom: 10px
}
.sub3{
  width:180px;
}
.subMenu{
  display: block;
}
.header {
  position: absolute;
  background-color:transparent;
  top: 0;
  width: 100%;
  height: 60px;
  line-height:60px;
  align-items:center;
  &.is-home { 
    background-color:rgba(255,255,255,0.60);
    z-index: 100;
  }
  &.is-fixed {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    color: #333; 
    background-color:#fff;
    position: fixed!important;
    animation: mymove 0.5s ease;
    -webkit-animation: mymove 0.5s ease;
    z-index: 100;
  }
  .header-md {
    position: relative;
    display: flex;
    justify-content:space-around;
    padding:0 40px;
    .logo {
      z-index: 8;
      img{
        height:32px;
        width:96px ;
        margin-top:18px
      }
    }
    .nav{
      z-index: 8;
      display: flex;
      li {
        font-size: 14px;                     
      }
    }
    .console {
      z-index: 8;
      font-size: 14px;
      flex: 1;
    }
    a{
      display: inline-block;
      height: 60px;
    }
  }
  .header-xs {
    position: absolute;
    width: 100%;
    margin-top: 0;
    z-index: 99999999;
    background: white;
    display: none;
    .logo {
      border-bottom: 1px solid #eaecef;
      .menu {
        float: left;
        width: auto;
      }
      .img {
        margin-top: 15px;
        height:32px;
        width:96px ;
        width: auto;
      }
    }
    ul {
      z-index: 999999999;
      position: absolute;
      background: #ffffff;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      padding-left: 10px;
      -moz-transition: max-height 0.25s ease;
      -webkit-transition: max-height 0.25s ease;
      -o-transition: max-height 0.25s ease;
      transition: max-height 0.25s ease;
      li {
        text-align: left;
        height: 40px;
        line-height: 40px;
        .sub{
          top: -60px;
          left: 70px;
          border-radius: 0 20px 20px 0;
          box-shadow: 0 0 10px  rgba(0, 0, 0, 0.1);
        }
        a {
          color: #333333;
          position: relative;
          padding-left: 6px;
          &.router-link-exact-active.router-link-active::before {
            content: '';
            height: 20px;
            width: 4px;
            background: #165DFF;
            position: absolute;
            left: 0px;
            top: 0px;
          }
        }
      }
      &.active {
        max-height: 500px;
      }
    }
  }
}
@keyframes mymove {
  from {
    margin-top: -160px;
  }
  to {
    margin-top: 0;
  }
}
@-webkit-keyframes mymove {
  from {
    margin-top: -160px;
  }
  to {
    margin-top: 0;
  }
}
@media (max-width: 1279px) {
  .outLi{
      margin: 0 20px;
  }
}
@media (max-width: 992px) {
  .header {
    height: 60px;
  }
  .header-md {
    display: none !important;
  }
  .header-xs {
    display: block !important;
  }
}
</style>
