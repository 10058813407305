import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    needJump: false, // 用于标记是否需要跳转锚点，跨路由跳转锚点的时候使用
    AboutAnchorPoint: '', // "关于我们"页面锚点
    //默认网站关键字
    metaInfo:{},
    locale: '', // 当前的语言-默认英文
    isEn:true,// 英文-标记
    isZh:false,// 中文-标记
    langList: [
      // 多语言列表
      {
        id: 'en',
        label: 'English'
      },
      {
        id: 'zh',
        label: '简体中文'
      },
    ]
  },
  getter: {},
  mutations: {
    // 修改网站关键字
    changeMetaInfo(state,data){
      state.metaInfo = data
    },
    // 修改跳转"关于我们"页面锚点
    changeAnchorPoint(state, id) {
      state.AboutAnchorPoint = id;
    },
    // 是否需要跳转锚点
    changeNeedJump(state, status) {
      state.needJump = status;
    },
    //Bear
    // 设置当前语言
    setLocale(state, data) {
      state.locale = data;
    },
    // 修改语言标记
    setEn(state, data) {
      state.isEn = data;
    },
    // 修改语言标记
    setZh(state, data) {
      state.isZh = data;
    },
  },
  actions: {
    pageJump(context, params) {
      console.log(params)
      if (params.path === '/about') {
        context.commit('changeAnchorPoint', params.id);
      }
    },
    changeStatus(context, status) {
      context.commit('changeNeedJump', status);
    }
  },
  modules: {}
});
export default store;
