<template>
  <div id="app" class="app" :class="isEn?'app-en':''">
    <e-header></e-header>
    <div class="main">
      <transition name="el-fade-in-linear" mode="out-in">
        <router-view :key="key"></router-view>
      </transition>
    </div>
    <e-footer></e-footer>
  </div>
</template>

<script>
import eHeader from './components/Header';
import eFooter from './components/Footer';
export default {
  name: 'App',
  components: {
    eHeader,
    eFooter
  },
  data() {
    return {};
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isEn:function(){
      return this.$store.state.isEn
    }
  }
};
</script>

<style>
.close{
    top: 16px;
    right: 16px;
    font-size: 24px;
    line-height: 1;
    transition: all .5s ease;
    width: 40px;
    position: absolute;
    padding: 0;
    background-color: rgba(0,0,0,.2);
    color: #fff;
    height: 40px;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    border-radius: 50%!important;
    outline: none;
    cursor: pointer;
    z-index: 11005;
}
.close::before{
  display: block;
    content: "";
    text-decoration: none;
    font-weight: 300;
    min-width: 16px;
    text-align: center;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik04IDcuMjkzbDUuMDg2LTUuMDg2YTEgMSAwIDAxMS40MTQgMEw4LjcwNyA4bDUuMDg2IDUuMDg2YTEgMSAwIDAxMCAxLjQxNEw4IDguNzA3bC01LjA4NiA1LjA4NmExIDEgMCAwMS0xLjQxNCAwTDcuMjkzIDggMi4yMDcgMi45MTRhMSAxIDAgMDEwLTEuNDE0TDggNy4yOTN6IiBpZD0iYSIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0tMjEtMjFoNTZ2NTZoLTU2eiIvPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PGcgbWFzaz0idXJsKCNiKSIgZmlsbD0iI0ZGRiI+PHBhdGggZD0iTTAgMGgxNnYxNkgweiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==);
}
.bj{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11000;
}
.box{
    max-width: 1200px;
    min-width: 300px;
    width: 93.75%;
    position: absolute; 
    left: 50%; 
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.cont-44{
  max-height: 252px;
  overflow: hidden;
}
.btn {
  cursor: pointer;
  display: inline-block;
  width: 96px;
  height: 40px;
  background: #ff6b00;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}
.btn:hover,
.btn:focus {
  color: #ffffff;
}
.text-44 {
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.text-44 .h-1{
  color: #ffffff;
  line-height: 40px;
}
.padd100-100{
  padding-top: 100px;
  padding-bottom: 100px;
}
.paddT100{
  padding-top: 100px;
}
.padd80-80{
  padding-top: 80px;
  padding-bottom: 80px;
}
.padd60-60{
  padding-top: 60px;
  padding-bottom: 60px;
}
.paddB80{
  padding-bottom: 80px;
}
.paddT80{
  padding-top: 80px;
}
.h-b{
  font-size: 36px;
  font-weight: 600;
  text-align: left; 
  color: #ffffff;
  line-height: 44px;
}
.h-1{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #0b0f14;
}
.h-3{
  font-size: 24px;
  font-family: Medium;
  font-weight: 500;
  text-align: center;
  color: #181d24;
}
.h-4{
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}
.h-7{
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  color: #181d24;
}
.p1{
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 30px;
}
.p2{
  font-size: 14px;
  font-weight: 400;
  color: #393f47;
  line-height: 30px;
}
.p5{
  font-size: 18px;
  font-weight: 400;
  text-align:left;
  color: #30363e;
  line-height:30px
}
.p3{
  font-size: 14px;
  font-weight: 400;
  text-align:left;
  color: #fff;
}
.p4{
  font-size: 14px;
  font-weight: 400;
  text-align:right;
  color: rgba(22,25,60,0.6);
}
.p6{
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
  color: #ffffff;
}
.p7{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #30363e;
}
.normal{
  line-height:normal!important;
}

.gapT10{
  margin-top: 10px;
}
.gapT20{
  margin-top: 20px;
}
.gapT30{
  margin-top: 30px;
}
.gapT40{
  margin-top: 40px;
}
.gapB60{
  margin-bottom: 60px;
}
.gapB64{
  margin-bottom: 64px;
}
.gapB50{
  margin-bottom: 50px;
}
.gapB40{
  margin-bottom: 40px;
}
.gapB32{
  margin-bottom: 32px;
}
.gapB30{
  margin-bottom: 30px;
}
.gapB20{
  margin-bottom: 20px;
}
.gapB10{
  margin-bottom: 10px;
}
.gapB48{
  margin-bottom: 48px;
}
.gapB12{
  margin-bottom: 12px;
}
.gapB34{
  margin-bottom: 34px;
}
.gapB24{
  margin-bottom: 24px;
}
.gapB80{
  margin-bottom: 80px;
}
.gap40-40{
  margin-top: 40px;
  margin-bottom: 40px;
}
.relative{
  position: relative;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
img{
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  object-fit: cover;
}
.weight400 {
  font-weight: 400 !important;
}
.weight600 {
  font-weight: 600 !important;
}
.center {
  text-align: center !important;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.white {
  color: white !important;
}

.black {
  color: #272727 !important;
}
.block {
  display: block !important;
}
.clear {
  clear: both;
}
html{
		font-size: 100%;
	}
.el-image__inner{
  width: 100%;
}
.el-image__inner img{
  position: relative;
}
.el-image__inner::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0,0,0,.02)
}
span,
li,
div,
html,
body,
ul,
p,
h2,
h3,
h4,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}
.app .el-image{
  display: block;
  margin-right: auto;
  margin-left: auto;
}
pre{
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

@media (max-width: 1440px) {
  .app-en .h-1{
    width:90%;
    margin-right:auto;
    margin-left:auto;
    font-size:30px;
  }
  .h-b,
  .h-1{
    font-size: 34px;
  }
  .h-3{
    font-size: 21px;
  }
  .h-7{
    font-size: 28px;
  }
  .p1,
  .p2,
  .p5,
  .p7{
    font-size: 16px;
  }
  .p2,
  .p1,
  .p5{
    line-height: 26px;
  }
  .p3,
  .p4,
  .p6{
    font-size: 13px;
  }
  
}
@media (max-width: 992px) {
  .main-frame{
    padding-top: 0;
  }
  .main {
    padding-top: 62px;
  }
}
@media (max-width: 428px) {
  .home-6 .h-1{
    width:90%;
    margin-right: auto;

    margin-left: auto;
  }
  .h-1,
  .h-b{
    font-size:24px
  }
  .app-en .h-1,
  .app-en .h-b{
    font-size:20px
  }
  .paddT80{
    padding-top:40px;
  }
  .two-box{
    padding-top:40px;
    padding-bottom:40px;
  }
  .padd80-80{
    padding-top:40px;
    padding-bottom:40px;
  }
}
</style>
