module.exports = {
  //公共-头部-主导航
  keywords_cont: 'Immotor , Immotor Technology Ltd , swapping , battery swapping for two-wheelers , battery swapping for electric vehicles , battery swapping for e-scooters',
  m_project_name: ' website',
  nav_1: 'Home',
  nav_2: 'Products',
  nav_3: 'Business',
  nav_4: 'Solutions',
  nav_5: 'About Us',
  //公共-头部-控制台
  nav_6: "Console",
  //公共-头部-第1列子导航
  nav_2_1: 'Battery Station',
  nav_2_2: 'Super Battery',
  nav_2_3: 'Charging Pile',
  nav_2_4: 'Smart E-blike',
  //公共-头部-第2列子导航
  nav_3_1: 'Battery Exchange',
  nav_3_2: 'Timesharing Rental',
  nav_3_3: 'Daily Rental',
  nav_3_4: 'Immotor e-Retail',
  nav_3_5: 'Immotor e-Patrol',
  //公共-底部-第3列导航
  nav_4_t: "Solutions",
  //公共-底部-第4列导航
  nav_5_1: "Company Profile",
  nav_5_2: "Corporate History",
  nav_5_3: "Corporate Culture",
  nav_5_4: "Latest News",
  nav_5_5: "Contact Us",
  //公共-底部--地址和电话
  adds: "T1-Building 12/F, Qianhai Kerry Business Center, Shenzhen, China",
  phone: "0755-2778-7220",
  //公共-按钮
  btn_1: "View Details >",
  btn_2: "Send Inquiry",
  btn_6: "Learn More >",
  btn_7: "NEW",
  btn_8: "MOST POPULAR",
  hh17_1: "Work with Us",
  hh17_2: "In-depth Cooperation towards Win-win Benefits",



  //首页-banner-文案
  hh11_1: "Evolving from Battery Charging to Battery Exchange",
  hh11_2: "Redefining Electric Two-wheel Vehicles Green Energy Network ",
  //首页-主标题
  hh12_1: "A Full",
  hh12_2: "Product Mix",
  hh13_1: "Assorted",
  hh13_2: "Business Scenarios",
  hh14_1: "Extensive and Valued",
  hh14_2: "Industry-specific Data Asset",
  hh15_1: "Battery Exchange City Coverage",
  hh15_2: "60+",
  hh16_1: "Join Forces",
  hh16_2: "with Industry Allies to Build the Future of Green Energy Network",
  hh18: "How It Works",
  //首页-副标题
  hh2_1: "Total User Served",
  hh2_2: "Total Order Completed",
  hh2_3: "Turnover",
  hh2_4: "Merchant Partners",
  hh2_5: "Cities Covered",
  hh2_6: "Primary Meeting",
  hh2_7: "On-site Assessment",
  hh2_8: "Contract Signing",
  hh2_9: "Training Support",
  hh2_10: "Business Launch",
  hh3_1: "Million",
  hh3_2: "Million",
  hh3_3: "Billion",
  hh3_4: "Partners",
  hh3_5: "Cities",
  hh3_6: "1",
  hh3_7: "300",
  hh3_8: "10",
  hh3_9: "1000",
  hh3_10: "60+",
  hh3_11: "",
  hh3_12: "over",
  //首页-段落
  hp_1: "Fast, easy, and safe intelligent battery exchange service for Electric Two-wheel Vehicles users, a premier solution to challenges of long charging hours, limited charging facilities and unsafe charging. The liberty of unlimited range anywhere, anytime.",
  hp_2: 'Smart battery of built-in chips, hosted on cloud server, featuring digital self-diagnostic technology, multi-level safety measures and large battery capacity design, bidding farewell to range anxiety, working with hardwired and cable connection, and more than 90% vehicle models.',
  hp_3: 'A supplement to battery exchange business to diversify Electric Two-wheel Vehicles energy supply offerings in residential, commercial and industrial area with instant and handy charging experience.',
  hp_4: 'New generation Electric Two-wheel Vehicles, compliant with China’s new national standard, setting the trend of mass customization.',
  hp_5: 'As of December 2020, Immotor is present in over 60 cities across China, including Chengdu, Wuxi, Beijing, Wuhan, Zhengzhou, Xi’an, Kunming, Shenzhen, Dalian, Shanghai, Nanjing, Guangzhou and Hefei.',
  //首页-城市
  c_1: 'Urumchi',
  c_2: 'Yinchuan',
  c_3: 'Beijing',
  c_4: 'Tianjin',
  c_5: 'Shijiazhuang',
  c_6: 'Dalian',
  c_7: 'Qingdao',
  c_8: 'Xuzhou',
  c_9: 'Zhengzhou',
  c_10: 'Luoyang',
  c_11: 'Xi’an',
  c_12: 'Xianyang',
  c_13: 'Changzhou',
  c_14: 'Kunshan',
  c_15: 'Suzhou',
  c_16: 'Shanghai',
  c_17: 'Wuxi',
  c_18: 'Nanjing',
  c_19: 'Taizhou',
  c_20: 'Wuhan',
  c_21: 'Wenzhou',
  c_22: 'Nanchang',
  c_23: 'Fuzhou',
  c_24: 'Ganzhou',
  c_25: 'Putian',
  c_26: 'Quanzhou',
  c_27: 'Xiamen',
  c_28: 'Jieyang',
  c_29: 'Dongguan',
  c_30: 'Guangzhou',
  c_31: 'Chaozhou',
  c_32: 'Shantou',
  c_33: 'Shenzhen',
  c_34: 'Zhongshan',
  c_35: 'Nanning',
  c_36: 'Jinghong',
  c_37: 'Yuxi',
  c_38: 'Qujing',
  c_39: 'Guiyang',
  c_40: 'Kunming',
  c_41: 'Dali',
  c_42: 'Yibin',
  c_43: 'Luzhou',
  c_44: 'Changsha',
  c_45: 'Zigong',
  c_46: 'Chongqing',
  c_47: 'Suining',
  c_48: 'Nanchong',
  c_49: 'Leshan',
  c_50: 'Meishan',
  c_51: 'Chengdu',
  c_52: 'Deyang',
  c_53: 'Mianyang',
  //招商加盟-banner-文案
  h1_1: 'All-in One Cloud-based IoT Solution for\nYour Green Energy Business',
  //招商加盟-主标题
  h2_1: "WHAT's NEW",
  h2_2: 'WHAT WE OFFER',
  h2_3: 'OUR SOLUTIONS',
  h2_4: 'WHY IMMOTOR',
  //招商加盟-view1-副标题
  h3_1: '4G/OTA-enabled Device Diagnostics\n& Remote Command',
  h3_2: 'Lego-like Modular Platform and APIs for\nBusiness Operations, Hardware Asset\nManagement & Data Access',
  h3_3: 'Unparallel configurability to manage your\ncustomer journey, pricing, campaign and\nsales network',
  h3_4: 'Work Order Management Tools for\nOptimal Maintenance Capacity Planning',
  //招商加盟-view2-副标题
  h3_5: 'IMMOTOR Driver App',
  h3_6: 'IMMOTOR API',
  h3_7: 'IMMOTOR O&M Toolkit',
  h3_8: 'IMMOTOR Monitoring Center',
  //招商加盟-view3-副标题
  h3_9: 'THE ABSOLUTE\nESSENTIALS',
  h3_10: 'THE FULL SUITE',
  h3_11: 'THE MOST AGILE &\nSCALABLE',
  //招商加盟-view4-副标题
  h3_12: 'Easy-to-implement\nEasy-to-use',
  h3_13: 'Fully Integrated\n tested and proven',
  h3_14: 'On-mobile \n& On-site',
  h3_15: 'International &\n Localized',
  //招商加盟-正文段落
  p2_1: 'Operations and Maintenance Made Faster and \nEasier.',
  p2_2: 'Enabling a host of capabilities from hardware \nasset registry, preventive and reactive \nmaintenance to staffing and team \ncoordination.',
  p2_3: 'Plug and Play Solution Promising Greater \nFlexibility.',
  p2_4: 'Built on a cloud architecture, easy to update \nand integrate. Open and standardized APIs \nseamlessly connecting all client-facing \ndevices to underlying management systems.',
  p2_5: 'Manage your Battery Exchange Infrastructure \nWherever you Go.',
  p2_6: 'Delivering reliable device uptime under multi-\nlayer communications with or without Internet \nconnections.',
  p2_7: 'Everything Tracked and Monitored at Your\nFingertips.',
  p2_8: 'Visually appealing infographics tool to\nshowcase your HAM (Hardware Asset\nManagement) data and business insight for\ndecision-making.',
  //智能换电柜-banner-文案
  zh1_1: 'Unlimited Range, Anytime Anywhere',
  zh1_2: 'Fast, easy, and safe intelligent battery exchange service for Electric Two-wheel Vehicles users, a premier solution to challenges of long charging hours, limited charging facilities and unsafe charging. The liberty of unlimited range anywhere, anytime.',
  //智能换电柜-主标题
  zh2_1: '24h-unmanned battery exchange station, programmed to monitor and manage battery charging settings to meet varying needs, operated independently and remotely',
  zh2_2: 'Station Model',
  zh2_3: 'Protected against accidental power outage, first-in-class backup power source',
  zh2_4: 'Future-proof dual fire prevention system',
  zh2_5: 'Supreme quality assurance from multi-layer safety features',
  zh2_6: 'Comprehensive Intelligent Safety Management',
  //智能换电柜-第1屏-副标题
  zh3_1: "Centralized, Data-Intelligent",
  zh3_2: "Outdoor Charging",
  zh3_3: "Real-time Data",
  zh3_4: "Collection and Analytics",
  zh3_5: "Self-service",
  zh3_6: "QR Code Battery Exchange",
  //智能换电柜-第2屏-副标题
  zh3_8: "Fireproof",
  zh3_9: "Theft-proof",
  zh3_10: "Automatic Thermostat Control",
  zh3_11: "Around-the-clock Monitoring",
  zh3_12: "GPS",
  zh3_13: "CAN",
  //智能换电柜-电柜类型-副标题
  zh3_14: "First Generation Intelligent Battery Exchange Station",
  zh3_15: "Super Battery Exchange Station",
  zh3_16: "Mini Battery Exchange Station",
  //智能换电柜-第4屏-副标题
  zh3_17: "Waterproof",
  zh3_18: "Fireproof",
  zh3_19: "Explosion-proof",
  zh3_20: "Lightning-proof",
  zh3_21: "Dustproof",
  //智能换电柜-全面的智能安全-副标题
  zh3_22: "Electrical Protection & Control",
  zh3_23: 'Advanced Battery Diagnostics',
  zh3_24: '24h Charging & Discharging Management',
  zh3_25: 'Digital Abnormality Detection',
  zh3_26: 'Heating/cooling Circulation System',
  //智能换电柜-第2屏-正文段落
  zp_1: 'Built-in water mist fire extinguishing feature, aerosol fire extinguisher and on-off fire switch',
  zp_2: 'Upgraded theft-proof feature with new lock on the side',
  zp_3: 'Enhanced heat sinking of charger and battery station (enlarged air outlet)',
  zp_4: '24h live monitoring',
  zp_5: 'Precise GPS positioning',
  zp_6: 'Significance performance improvement with CAN',
  //智能换电柜-电柜类型-正文段落
  zp_7: 'Multi-level safety features marked by explosion-proofness and fire prevention. Programmable temperature control of cooling/heating. Offline battery changing and around-the-clock monitoring.',
  zp_8: 'High-power charging and faster charging Elevated safety performance with dual fire prevention system. Backup charger as alternative supply, uninterrupted by power outage. New theft-proof design to effectively protect battery against loss and theft.',
  zp_9: '7-locker battery exchange station, light-weight, easy to install and maintain. Small power, accommodating different conditions.',
  //超级电池-banner-文案
  ch1_1: 'New Generation\nSuper Battery',
  ch1_2: 'Smart battery of built-in chips, hosted on cloud server, featuring digital self-diagnostic technology, multi-level safety measures and large battery capacity design, bidding farewell to range anxiety, working with hardwired and cable connection, and more than 90% vehicle models',
  //超级电池-主标题
  ch2_1: 'Computerized Software Management',
  ch2_2: 'Battery Model',
  ch2_3: 'Smart battery chips',
  ch2_4: 'Multiple patented technologies and safety certifications',
  ch2_5: 'Innovative glue filling techniques',
  ch2_6: 'Multi-level protection for greater safety and reassurance',
  ch2_7: 'Alternative cable connection\nInstant battery exchange within 3 seconds',
  ch2_9: 'Quality Assurance, Intelligent Manufacturing',
  //超级电池-智能化软件管理-副标题
  ch3_1: 'Built-in MPU',
  ch3_2: 'Battery Life Cycle Management',
  ch3_3: 'ID Certification\nPower On Demand',
  ch3_4: '4G',
  //超级电池-电池类型-副标题
  ch3_5: 'Super S',
  ch3_6: 'Li-ion Battery',
  ch3_7: 'Super X',
  ch3_8: 'Li-ion Battery',
  ch3_9: 'Super Y',
  ch3_10: 'LFP Battery',
  //超级电池-众多专利技术、多项安全认证-副标题
  ch3_11: 'Cutting-edge Design',
  ch3_12: 'Technological Innovation',
  ch3_13: 'Quality Assurance',
  //超级电池-多重防护更加安全安心-副标题
  ch3_14: 'Safety Certifications',
  ch3_15: 'Safety Early Warning',
  ch3_16: 'Real-time Monitoring',
  ch3_17: 'Data Connectivity',
  ch3_18: 'Structural Protection',
  //超级电池-电池类型-正文段落
  cp_1: 'New glue filling techniques for improved safety performance Large capacity design for greater riding range Standardized module for stronger power Cable or hardwired connection, powering a series of different vehicles.',
  cp_2: 'Conformance to China’s new National Standard New module design of smarter battery Excellent high-temp/low-temp performance, longer service life, appealing product appearance Human-centered ergonomic design for easy battery insertion and removal.',
  cp_3: 'Lithium iron phosphate battery Lighter, with larger capacity and enhanced high-temp performance promising safer and longer service.',
  //超级电池-采用智能芯片-正文段落
  cp_4: "Digital self-diagnostic technology, Governed by Smart MPU, Proprietary protocol, cloud-based solution for real-time processing of big data,Abnormality detection and alert, device self-diagnosis and remote restart,Strengthened safety feature of early warning triggered by single battery chip.",
  //超级电池-众多专利技术、多项安全认证-正文段落
  cp_9: 'Integrated battery exchange network.\nManaged across the entire industry chain.\nModularized battery and standardized design Latest battery series & parallel connection technology.',
  cp_10: 'In-house developed cloud computing system.\nReal-time data collection and analytics of a daily volume of 150GB Advanced BMS and PMS controller technology.\nPromising exceptional battery safety and performance.\nIndependently-developed smart chips for battery lifecycle management.',
  cp_11: 'Product design incorporated in refined craftsmanship Independent high-quality production line.\nAutomotive grade battery cell IP66 battery PACK technology Reliable outdoor service.',
  //超级电池-标题段落
  cp_16: 'Innovative glue filling techniques for battery chips with guaranteed safety',

  cp_17: 'Increasingly reliable connection, lower barrier at lower cost',

  cp_18: 'Automated battery production line',
  //充电桩-banner-文案
  ph1_1: 'Immotor e-Charging',
  ph1_2: 'City-wide Intelligent Management\nSolution for Electric Two-wheel Vehicles',
  //充电桩-主标题
  ph2_1: "Immotor e-Charging",
  ph2_2: "10+layer Charging Protection, with Guaranteed Safety",
  //充电桩-易马达e充电-副标题
  ph3_1: 'Standard Charging Socket',
  ph3_2: 'Three-level Charging Protection',
  ph3_3: 'Mobile Payment Supported',
  ph3_4: '4G Communications',
  ph3_5: 'Three-level Hazard Prevention',
  ph3_6: 'Multi-platform Integration',
  //充电桩-超十重防护 安全有保障-副标题
  ph4_1: 'Memory Protection',
  ph4_2: 'Short-circuit Protection',
  ph4_3: 'Toppling Prevention',
  ph4_4: 'Early Warning against Overload',
  ph4_5: 'Over-temp Protection of Charging Station ',
  ph4_6: 'Overload Protection',
  ph4_7: 'Earth Leakage Protection',
  ph4_8: 'Input Over-voltage/under-voltage Protection ',
  ph4_9: 'Fire Warnings',
  ph4_10: 'Anti-unauthorized Charging',
  ph4_11: 'Zero-load Protection',
  ph4_12: 'Power Theft Prevention',
  //充电桩-项目-副标题
  ph5_1: 'Method of installation',
  ph5_2: 'Housing material',
  ph5_3: 'Total power',
  ph5_4: 'Input voltage (V)',
  ph5_5: 'Input frequency (Hz)',
  ph5_6: 'Single-line maximum power (W)',
  ph5_7: 'Single-line maximum output current (A)',
  ph5_8: 'Standby power loss',
  ph5_9: 'Measurement accuracy',
  ph5_10: 'Operating temperature',
  ph5_12: 'Operating humidity',
  ph5_13: 'Operating altitude',
  ph5_14: 'INGRESS PROTECTION Level',
  ph5_15: 'Noise control',
  ph5_16: 'MTBF(h)',
  //充电桩-内容-副标题
  ph6_1: 'Wall Mount/Pedestal Mount',
  ph6_2: 'PVC Label and flame retardant galvanized sheet',
  ph6_3: '7KW (32A)',
  ph6_4: 'AC220 ± 20%',
  ph6_5: '50± 10%',
  ph6_6: '600W(700W protection)',
  ph6_7: '3.5 A',
  ph6_8: '≤2.0W',
  ph6_9: '± 1.0% /0.02 yuan',
  ph6_10: '-20°C~60°C',
  ph6_12: '5%~95%',
  ph6_13: '2000m',
  ph6_14: 'IP56',
  ph6_15: '≤60dB',
  ph6_16: '100,000',
  //充电桩-表格-标题
  ph7_1: 'Product',
  ph7_2: 'Item',
  ph7_3: 'Information',
  //充电桩-易马达e充电-正文段落
  pp_1: 'Plug and trunking installation compliant with new national standard',
  pp_2: 'Overcharge protection, multi-charging protection, timing protection',
  pp_3: 'Mobile Payment supported, tiered pricing',
  pp_4: '4G-enabled high-speed data upload',
  pp_5: 'Water-resistant, fire-proof and dust-proof',
  pp_6: 'Options of connecting fire control center, police monitoring platform and property monitoring platform',
  //超级换电车-banner-文案
  bh1_1: 'New national standard compliant\nsuper smart vehicle',
  bh1_2: 'New National Standard-Compliant Smart E-blike',
  //超级换电车-主标题
  bh2_1: 'Diversified Products and Services',
  bh2_2: 'Small groups, urban transport,and other scenarios',
  //超级换电车-副标题
  bh3_1: 'One-click\nLocking',
  bh3_2: 'One-click\nReservation/Navigation',
  bh3_3: 'One-click\nBattery Exchange',
  bh3_4: 'Auto Hold\nParking Function',
  bh3_5: 'Find Your\nVehicle on App',
  //e巡逻业务-banner-文案
  eh1_1: '5G Smart City\nPatrol Control System of Law Enforcement',
  //e巡逻业务-主标题
  eh2_1: "Our Social Values",
  eh2_2: "Architecture of Smart City law enforcement patrol control system",
  eh2_3: "Features of Smart City law enforcement patrol control system",
  eh2_4: "Big Data Empowerment",
  //e巡逻业务-副标题
  eh3_1: 'Rechargeable Electric Two-wheel Vehicles Management Platform',
  eh3_2: 'Cloud-based Battery Exchange Station platform',
  eh3_3: '5G Smart City Patrol Control System of Law enforcement',
  eh3_4: 'Big Data Empowerment',
  eh3_5: 'Battery Exchange Network Support',
  eh3_6: 'Chengdu municipal patrol control system of law enforcement',
  eh3_7: 'Unlimited Range\nBattery Exchange Anytime',
  eh3_8: 'New Approach to Law Enforcement\nPatrol Collaboration',
  eh3_9: 'New Approach to Interacting with\nGeneral Public',
  eh3_10: 'Real-time Full-scope Monitoring of\nPatrol Vehicles',
  eh3_11: 'Unlimited Range, Battery Exchange Anytime',
  eh3_12: 'Real-time Full-scope Monitoring of Patrol Vehicles',
  eh3_13: 'New Approach to Law Enforcement Patrol Coordination',
  //e巡逻业务-第2，4屏-正文段落
  ep_1: '5G-based real-time vehicle monitoring and low-latency video media interaction for new urban surveillance model.',
  ep_2: '5G-backed larger data loading volumes and closer monitoring to prompt faster response.',
  ep_3: '5G battery exchange network and new generation infrastructure as the basis of 5G Smart City patrol control system of law enforcement.',
  ep_4: 'Law enforcement patrol officers can avail themselves of various battery exchange options to increase riding range and uptime: Scan & Exchange (QR code-based) Reserve & Exchange, Semi-offline Exchange and Offline Exchange.',
  ep_5: 'Headquarters officers are well-equipped to monitor, interact with, and dispatch patrol vehicles from office TV dashboard, Monitoring Center and other desktop software.',
  ep_6: 'You can access live location and vehicle routes, communicate with vehicle on two-way radio (similar to walkie talkie), launch video-chat with vehicle, assign work order, and mobilize manpower resources where needed.',
  ep_7: 'Patrol enforcement officers in the field can collaborate via mobile APP.',
  ep_8: 'You can find nearby patrol officers, communicate with them on two-way radio, and team up with them online on patrol.',
  ep_9: 'Battery exchange order by patrol law enforcement officers is prioritized for processing.',
  //e巡逻业务-大数据赋能-正文段落
  ep1_1: 'Big Data Analytics: ',
  ep1_2: 'Patrol findings in patrol area',
  ep1_3: 'Law enforcement work order processing in patrol area',
  ep1_4: 'Municipal management in patrol area',
  ep1_5: 'Patrol manpower consumption in patrol area',
  ep1_6: 'Public satisfaction rating of law enforcement in patrol area',
  //新零售业务-banner-文案
  nh_1: 'New Retail A New Extension of Battery Exchange Business',
  nh_3: 'Vehicle\nSales',
  nh_4: 'Used\nVehicles',
  nh_5: 'Vehicle\nRental',
  nh_6: 'Vehicle\nRepair',
  nh_7: 'Vehicle\nMaintenance',
  nh_8: 'Battery\nHub',
  nh_9: 'EV\nLife',
  nh_10: 'EV\nRiding',
  nh_11: 'Community\nBased',
  //新零售业务-主标题
  nh2_1: 'Wide-ranging Service and Features',
  nh2_2: 'How to Monetize e-Joy',
  nh2_3: 'New Retail Business Concept',
  nh2_4: 'Our Social Values',
  //新零售业务-丰富的服务功能-副标题
  nh3_1: "WeChat Marketing",
  nh3_2: "Digital Ad Poster",
  nh3_3: "Remote Shop Exploration",
  nh3_4: "Data Analysis",
  nh3_5: "Sales with Live Stream",
  nh3_6: "Coupons",
  nh3_7: "Content Distribution",
  nh3_8: "Merchandise Management",
  //新零售业务-欢电的变现能力-副标题
  nh3_9: "Transaction Commission",
  nh3_10: "Content/Ad",
  nh3_11: "SaaS",
  nh3_12: "App Store",
  //新零售业务-新零售经营概念-副标题
  nh3_13: "Smart Electric Two-wheel Vehicles,R&D and Production",
  nh3_15: "Supply Chain Management\nSaas System",
  nh3_17: "E-commerce,Online Sales Lead and Transaction",
  nh3_19: "Electric Two-wheel Vehicles,Rental & Sales",
  nh3_21: "Recruitment\nTraining Service",
  nh3_23: "Daily Necessities\nWholesale & Retail",
  //新零售业务-我们的社会价值-副标题
  nh4_1: "Building New National Standard Compliance and Smartness",
  nh4_2: "Electric Two-wheel Vehicles",
  nh4_3: "Electric Two-wheel Vehicles Shop",
  nh4_4: "Supply Chain Management",
  nh4_5: "Instant Delivery Industry",
  nh4_6: "Recruitment & Training",
  nh4_7: "Promoting E-commerce",
  nh4_8: "of Electric Two-wheel Vehicles Shop",
  //新零售业务-正文段落
  np_1: 'Integrate your WeChat ecosystem to tap into the potential of your private domain traffic to drive online sales',
  np_2: 'Customize your graphic resources to create eyecatching posters with one click under efficient digital promotion',
  np_3: 'Video marketing on shop level, recommendations interwoven with online coupon release, closing transactions during chi-chat',
  np_4: 'Precise metrics and indicators to quantify shop operations, real-time tracking orders, sales and conversion rate',
  np_5: 'Revolutionary sales with live streaming, cross-selling to increase bulk order',
  np_6: 'The variety of coupons and vouchers at your disposal: goods coupons, shop vouchers and campaign coupons, Cash Back, discount vouchers, flash sales deals',
  np_7: 'Digital content marketing to boost sales and conversion, to identify new opportunity towards measurable, attributable and optimizable business growth',
  np_8: 'Spider web-structured merchandise management connecting different retailers for higher, e-commerce operational efficiency',
  np_9: 'Commission for sales/service transactions, VIP monthly charges',
  np_10: 'Brand advertising/business owner micro-advertising/content creation and other revenue sharing',
  np_11: 'Fees for physical resource management/work order service/big data analysis and other shared SaaS modules',
  np_12: 'Engaging industry-specific application for revenue split with third-party developers',
  np_13: 'A proponent of government-led green energy campaign, dedicated to promoting the new national standard compliant smart Electric Two-wheel Vehicles',
  np_14: 'A provider of supply chain services to retailers including vehicles/accessories/parts purchase management and SaaS inventory management with significant cost benefit',
  np_15: 'A partner in assisting delivery platforms to fulfill CSR of employment and poverty alleviation. We take the initiative to introduce riders recruitment to Immotor New Retail outlets to cope with rapid-growing labor demand amid the industry boom. We also build and enhance a training framework to educate new hires',
  np_16: 'A catalyst for e-retailer sales and revenue creation based on WeChat Mini Program ecosystem. Leveraging O2O dividends, we excel in directing leads, facilitating shop opening, and closing deals in 24 hours',
  //天天租业务-banner-文案
  dh_1: "Daily Rental Service",
  dh_2: "Under the full fledged battery exchange network we built, our daily rental service is a god-send to different stakeholders: faster and easier charging for customers, whereas for offline retailers, we help digitize user engagement, linking them closer to customers, vehicle/battery manufacturers and battery exchange operators, and boosting vehicle rental & sales in the increasingly connected Electric Two-wheel Vehicles community.",
  //天天租业务-主标题
  dh2_1: "Pain points of the Vehicle Rental Industry",
  dh2_2: "IMMOTOR\nCommitted to Make it Easy to Run Rental Business\nAnywhere in the World",
  dh2_3: "Features of Immotor Daily Rental Platform",
  dh2_4: "Hardware Product Upgrade",
  //天天租业务-副标题
  dh3_1: "Order overdue",
  dh3_2: "Property Loss",
  dh3_3: "Limited Charging Access",
  dh3_4: "Absence of Marketing",
  dh3_5: "Shortage of Online Traffic",
  dh3_6: "Vehicle Rental SaaS Platform",
  dh3_7: "Asset Management Made Simple",
  dh3_8: "Robust Battery Exchange Infrastructure",
  dh3_9: "Wide-ranging User Cases",
  dh3_10: "Minimize Order Overdue & Customer Loss",
  dh3_11: "Various Marketing Models on Offerings",
  dh3_12: "Smart Battery Chamber (vehicle)",
  dh3_13: "Wireless Communication",
  dh3_14: "Intelligent Vehicle Management Platform",
  //天天租业务-正文段落
  dp_1: "Rental vehicle is in unauthorized use long after rental package/plan expires",
  dp_2: "Lost vehicles are beyond recovery, posting high risk of property loss.",
  dp_3: "The challenge of limited charging options for rental vehicles",
  dp_4: "Poorly-devised, next-to-nothing marketing tools",
  dp_5: "Zero digital sales leads and traffic, almost exclusively location-based offline marketing",
  dp_6: "Immotor Vehicle Rental Platform, attracting vehicle dealers of all sizes to join and prosper in e-commerce, not charging service fees or commission",
  dp_7: "Data-driven asset management software to enable informed decision-making and operations. Smart battery chamber with GPS and and 4G module embedded to track rental vehicle with live routes map displayed.",
  dp_8: "Backed by Immotor’s proven infrastructure, customer has the priviledge of secured battery access and hassle-free battery exchange experience with rental vehicle.",
  dp_9: "Inclusive service model supporting vehicle rental (weekly/monthly/quarterly), short-term vehicle sharing (Scan & Rent) to riders, in addition to listing of idle vehicle by owners, and retail business going forward.",
  dp_10: "Rental-battery exchange binding control to minimize late vehicle return. An expired rental order will render its linked battery exchange plan invalid, leading to service suspension and prompting package renewal.",
  dp_11: "Assorted marketing tools for free, available to vehicle dealers, including deposit-free rental (with Zhima Credit), Huabei installments, coupons, and more promotion options on the way",
  dp_12: "Featuring GPS, battery management, anti-theft alarm and device self-diagnosis",
  dp_13: "4G and Bluetooth enabled seamless connection and live communication: cloud platform-vehicle, APP-vehicle",
  dp_14: "360 vehicle monitoring, OTA upgrade, remote vehicle search, etc.",
  //天天租业务-标题段落
  dp_15: 'Diverse service and features to catalyze your customer engagement and retention',
  // 分时租业务-banner-文案
  rh_1: 'Timesharing Rental Solution',
  rh_2: 'Vehicle rental and accompanying battery exchange service for walk-in customers, a hybrid of leasing and battery service Leading the industry and empowering the society as a whole with innovation and technology',
  // 分时租业务-主标题
  rh2_1: 'Why Immotor Timesharing Rental',
  rh2_2: 'One-stop Marketing Solution',
  rh2_3: 'What Immotor Timesharing Rental Offers',
  rh2_4: 'How to Use Immotor Timesharing Rental',
  rh2_5: 'Timesharing Rental vs E-motorcycle Sharing',
  rh2_6: 'Extensive capabilities to make your rental experience safe, comfy and hassle-free',
  // 分时租业务-第2，3屏-副标题
  rh3_1: 'Vehicle Rental\nAnytime Anywhere',
  rh3_2: 'One-click Rental & Return\nGuaranteed Riding Range',
  rh3_3: 'Easy, Comfy Travel\nIntelligent Vehicle Use ',
  rh3_4: 'Transparent Pricing\nAffordable Service',
  rh3_5: 'Point of Interests',
  rh3_6: 'Office Buildings',
  rh3_7: 'Hotels',
  rh3_8: 'Communities/Residences',
  rh3_9: 'University Campus',
  rh3_10: 'Factories',
  // 分时租业务-分时租产品使用流程-副标题
  rh3_11: 'QR Code Scan & Rent on APP/Mini Program',
  rh3_12: 'Pay Deposit or Choose Deposit-free Options (partnered with Alipay Zhima Credit)',
  rh3_13: 'Start your ride and charge by hourly rate',
  rh3_14: 'At the end of your ride, find the nearest spot to return the vehicle as per instructions ',
  rh3_15: 'End of ride and end of service',
  // 分时租业务-对比-副标题
  rh3_16: 'E-motorcycle Sharing',
  rh3_17: 'Timesharing Rental',
  // 分时租业务-丰富功能为您的出行保驾护航-副标题
  rh3_18: 'Find/return your Vehicle/',
  rh3_19: 'in Map View',
  rh3_20: 'Vehicle Details',
  rh3_21: 'in One Place',
  rh3_22: 'QR Code Scan & Rent',
  rh3_23: 'Walk-in Rental on Demand',
  rh3_24: 'Live Monitoring',
  rh3_25: 'Accurate Positioning',
  rh3_26: 'Battery Level Alert',
  rh3_27: 'Fixed Return Spot',
  rh3_28: 'Data Connectivity',
  rh3_29: 'Intelligent Services',
  // 分时租业务-对比-正文段落
  rp_1: 'Riding range',
  rp_2: 'limited range',
  rp_3: 'unlimited range',
  rp_4: 'Battery',
  rp_5: 'high manual maintenance cost',
  rp_6: 'self-service battery exchange',
  rp_7: 'Rental Drop-off',
  rp_8: 'find on the map',
  rp_9: 'physical reference, fixed return spot',
  rp_10: 'Participating Parties',
  rp_11: 'brand owners/large agents',
  rp_12: 'mutually beneficial, SME businesses are welcome ',
  rp_13: 'Extensibility',
  rp_14: 'closed system',
  rp_15: 'open system, potentially engaging sub-dealers',
  rp_16: 'Use Scenarios',
  rp_17: 'general',
  rp_18: 'specific',
  rp_19: 'Target Segment',
  rp_20: 'mass market',
  rp_21: 'scenario-specific, with a more pressing need',
  rp_22: 'Vehicle Damage Risk',
  rp_23: 'beyond control',
  rp_24: 'within control',
  rp_25: 'Pricing',
  rp_26: 'fixed charges',
  rp_27: 'multiple pricing plans available',
  // 分时租业务-一站式营销解决方案-正文段落
  rp2_1: 'Touring the scenic spots on rental vehicle',
  rp2_2: 'Daily commute of office workers',
  rp2_3: 'Sightseeing tours of hotel guests',
  rp2_4: 'Running errands such as grocery shopping',
  rp2_5: 'On-campus short trips (university campus)',
  rp2_6: 'On-campus short trips (industrial park)',
  // 分时租业务-一站式营销解决方案-标题段落
  rp3_1: 'Diverse service and features to catalyze your customer engagement and retention',
  //e换电业务-banner-文案
  mh_1: 'A Builder of Global Intelligent Energy Network Ecosystem',
  //e换电业务-主标题
  mh2_1: 'Wide-ranging Products Features & Services',
  mh2_2: 'Our Unswerving Commitment to R&D of Core Technologies in Creating New Generation Infrastructure Ecosystem in Travel',
  mh2_3: 'Battery Exchange Options Applicable to Various Scenarios',
  mh2_4: 'Unlimited Range, Anytime Anywhere',
  mh2_5: 'Instant Battery Exchange in under 3 Seconds, Sparing the Trouble for Charging',
  mh2_6: 'Immotor Battery Exchange VS Traditional Battery Charging',
  //e换电业务-丰富的产品与服务功能-副标题
  mh3_2: 'Reservation\n& Exchange',
  mh3_3: 'Offline Battery\nExchange',
  mh3_4: 'Map\nNavigation',
  mh3_5: 'Station\nInformation',
  mh3_6: 'Vehicle\nInformation',
  mh3_7: 'Battery\nData',
  //e换电业务-换电模式适用于多种场景-副标题
  mh3_8: 'Express & Food Delivery',
  mh3_9: 'Daily Transport',
  mh3_10: 'Public Sector Use',
  mh3_11: 'Campus and Parks',
  mh3_12: 'Points of Interests',
  //e换电业务-随时随地 无线续航-副标题
  mh3_13: '60+',
  mh3_14: '8000+',
  mh3_15: '600,000+',
  mh3_16: '>1,000,000,000',
  //e换电业务-3秒换电 从此无需充电-副标题
  mh4_1: 'Premium Quality Battery for Unlimited Range',
  mh4_2: 'Instant Battery Exchange within 3 Seconds, Anytime Anywhere',
  mh4_3: 'Intelligent Device Diagnosis, 5-level Protection',
  mh4_4: 'Centrally Managed Smart Lithium Battery',
  //e换电业务-对比-副标题
  mh4_5: 'Safe and Reliable',
  mh4_6: 'Worry-free',
  mh4_7: 'Cost-effective',
  mh4_8: 'Readily accessible',
  mh4_9: 'Time-saving',
  mh4_10: 'Safety Hazards',
  mh4_11: 'Vehicle Stolen or Loss',
  mh4_12: 'High Cost',
  mh4_13: 'Inconvenient Charging',
  mh4_14: 'Slow Charging',
  //e换电业务-表单-标题
  mh5_1: 'Immotor Battery Exchange\nSolution',
  mh5_2: 'Pain Points of Traditional Electric Two-wheel Vehicles Charging',
  //e换电业务-标题段落
  mp_1: 'Smart Charging Piles  Trendy E-motorcycle  New Generation Battery Exchange Stations  Ultra Safe Batteries',
  mp_2: 'City-wide Electric Two-wheel Vehicles mobility ecosystem. Battery-as-a-Service subscription\nDensely-populated battery exchange stations. Tailored to your needs',
  mp_3: 'From charging to battery exchange, an industry pioneer reinventing a green energy network infrastructure for Electric Two-wheel Vehicles',
  //e换电业务-丰富的产品与服务功能-正文段落
  mp2_1: 'Running on a draining battery? Use your APP to reserve a battery of your specifications for fast battery exchange.',
  mp2_2: 'In the unlikely event of server disconnection (caused by network or hardware failure), you can operate the LCD screen of battery station to perform offline battery exchange with or without the Internet.',
  mp2_3: 'One-click navigation on the APP to show you optimal routes to the destination battery station.',
  mp2_4: 'Device parameters all in one place: Location of battery exchange station,  battery charging level, operating hours, number of service-ready batteries of different models.',
  mp2_5: 'Full ownership in managing the routes, live position, abnormality warnings, one-click start/lock switch of your vehicle.',
  mp2_6: 'Access to battery details at your fingertips in My Battery module: battery ID, battery temperature, battery capacity and other information.',
  //e换电业务-换电模式适用于多种场景-正文段落
  mp3_1: 'A great help to riders in express delivery, takeout and flash delivery sector. Greater uptime and productivity on the back of instant battery exchange and unlimited riding range.',
  mp3_2: 'A safe and handy option for personal user, free of charging constraints and safety hazards. Enjoy around-the-clock battery exchange backed by Immotor green energy network. Flexible pricing plan supported: monthly subscription or pay per use.',
  mp3_3: 'A facilitator of government-led efforts and development in urban mobility, management and other municipal services, drawing on the strengths of green energy network infrastructure and big data analytics (i.e. vehicle routes and operating state).',
  mp3_4: 'A new solution to short-distance transit in sizable college towns or industry parks. Immotor battery exchange facilities are well-positioned to make life easier for on-campus employees, staff members and students as they go about their daily chores.',
  mp3_5: 'A new form of in-destination vehicle rental for tourists. To start your ride, scan QR code to rent a vehicle. When low on battery, find the nearest battery station as instructed on app to swap a battery and off you go again.',
  //e换电业务-随时随地 无线续航-正文段落
  mp4_1: 'City',
  mp4_2: 'Full coverage of tier 1 cities and prioritized expansion into new tier 1 cities',
  mp4_3: 'Battery Stations',
  mp4_4: 'Established and optimized battery exchange network',
  mp4_5: 'Batteries/Day',
  mp4_6: 'An accumulation of 300,000,000+ batteries exchanged',
  mp4_7: 'KM',
  mp4_8: 'Equivalent to the distance of 9 round trips between the Earth and Mars',
  //e换电业务-对比-正文段落
  mp5_1: 'Centralized, visualized and standardized outdoor charging throughout the process',
  mp5_2: 'Built-in developed fire safety measures',
  mp5_3: 'GPS recovery rate 95%+',
  mp5_4: 'Lifecycle battery management and battery service phasing.',
  mp5_5: 'Battery on Rental',
  mp5_6: 'No Need for Purchase',
  mp5_7: 'Lightweight battery, operable with one hand and in offline settings',
  mp5_8: 'QR Code scanning supported, fast and easy',
  mp5_9: 'Free of charging, battery exchange in 3 seconds',
  mp5_10: 'Battery Exchange, Anytime Anywhere',
  mp5_11: 'Battery charging is vulnerable to safety hazards',
  mp5_12: 'Risk of fire and explosion',
  mp5_13: 'No GPS positioning',
  mp5_14: 'Beyond recovery once lost',
  mp5_15: 'Batteries priced at 1,500 yuan',
  mp5_16: 'Regular replacement and high maintenance cost required',
  mp5_17: 'Bulky, hard to carry around for charging. ',
  mp5_18: 'Battery charging is susceptible to fire risk',
  mp5_19: '2-8 hours of charging, unfit for emergency use',
  mp5_20: 'Daily charging cost of￥5+',
  //about-页面导航
  n_1: 'Company Profile',
  n_2: 'Company History',
  n_3: 'Corporate Culture',
  n_4: 'Latest News',
  //about-主标题
  ah_1: 'Company Profile',
  ah_2: 'Company History',
  ah_3: 'Corporate Culture',
  ah_4: 'Latest News',
  //about-发展历程-副标题
  ah2_1: '2015',
  ah2_2: '2016',
  ah2_3: '2017',
  ah2_4: '2018',
  ah2_5: '2019',
  ah2_6: '2020',
  //about-我们努力践行的文化-副标题
  ah3_1: 'Corporate Vision',
  ah3_2: 'Corporate Mission',
  ah3_3: 'Corporate Spirit',
  //about-最新资讯-时间
  nt_1: '2021-02-26',
  nt_2: '2021-01-29',
  nt_3: '2021-01-22',
  nt_4: '2020-12-24',
  nt_5: '2020-12-18',
  nt_6: '2020-12-17',
  nt_7: '2020-12-17',
  nt_8: '2020-12-17',
  nt_9: '2020-11-15',
  nt_10: '2020-09-21',
  nt_11: '2020-06-30',
  nt_12: '2020-06-15',
  nt_13: '2020-06-02',
  nt_14: '2020-05-25',
  nt_15: '2020-03-25',
  nt_16: '2020-03-23',
  nt_17: '2020-03-19',
  nt_19: '2020-03-18',
  nt_20: '2020-03-11',
  nt_21: '2020-01-19',
  nt_22: '2020-01-19',
  nt_23: '2019-12-18',
  nt_24: '2019-11-23',
  nt_25: '2019-10-27',
  nt_26: '2019-09-20',
  nt_27: '2019-06-13',
  nt_28: '2019-06-12',
  nt_29: '2019-05-31',
  nt_30: '2019-03-26',
  nt_31: '2018-12-19',
  nt_32: '2018-12-25',
  nt_33: '2018-12-24',
  nt_34: '2018-08-20',
  nt_35: '2018-05-22',
  nt_36: '2018-05-08',
  nt_38: '2018-04-11',
  nt_39: '2018-01-11',
  nt_40: '2017-12-19',
  nt_41: '2017-12-15',
  nt_42: '2017-12-16',
  //about-最新资讯-标题
  ah4_1: 'Immotor Featured in CCTV News and Electric Two-wheel Vehicles Market Ready for a Boom',
  ah4_2: 'Deputy to the NPC Proposes an Electric Two-wheel Vehicles Battery Exchange Energy System in Response to Electric Two-wheel Vehicles Charging Challenges, ',
  ah4_3: 'Immotor Lands on Integrity List 2020 by Pencil Never Lies',
  ah4_4: 'Intelligent Energy Network Creates A New Ecosystem of Electric Two-wheel Vehicles',
  ah4_5: 'Immotor Provides All-in-one Solutions to Urban Electric Two-wheel Vehicles Management in the New Era of Green Commuting',
  ah4_6: 'With Service Range over 1 Billion KM, Immotor Adds Super Battery and More to its Product Mix',
  ah4_7: 'Immotor Launches Innovative Products, Empowering Electric Two-wheel Vehicles with Technology and Intelligence',
  ah4_8: 'Immotor Releases New Products as Part of its Evolving Technology-empowered Big Electric Two-wheel Vehicles Commuting Strategy',
  ah4_9: 'Immotor at the High-tech Fair: Unveiling New Charging Solutions to Electric Vehicles',
  ah4_10: 'Immotor Closes Series C1 Financing Led by Top Capitalists from China, Europe, Japan, South Korea and U.S.',
  ah4_11: 'First Online Show of Electric Vehicles, Immotor Fills the Gap of Battery Exchange Market',
  ah4_12: 'First Electronic Vehicle Show in 2020, Immotor Attracts Extensive Attention',
  ah4_13: 'Guangdong Provincial Emergency Response Department Holds a Symposium on Safety Management Of Electric Bicycle Charging Facilities',
  ah4_14: 'Boarding the New Infrastructure Express, Electric Vehicle Battery Exchange Embraces New Development Opportunities',
  ah4_15: 'Immotor Expected to Go Global, Recognized by South Korean Press for Extraordinary Performance in the Fight against COVID-19',
  ah4_16: 'The 1960s-born and 1990s-born in the Fight against COVID-19, Behind-the-scene Stories of Immotor Battery Exchange Service Network',
  ah4_17: 'The Battery Exchange Shift-worker behind the Riders: Battery Security is Contributing to the Fight against COVID-19',
  ah4_19: 'Sweeping Disinfection and Sterilization and Point-to-point Communication! The 1990s-born at the Unmanned Battery Exchange Stations',
  ah4_20: 'The ‘Gas Station’ for the Takeout Riders: Unmanned Battery Exchange Stations in the COVID-19 Outbreak',
  ah4_21: 'Suzhou Gusu Yungu Hongyinzhiqi Service Center Inaugurated and Entered by 6 Key Projects',
  ah4_22: 'Favorable Industrial Policies Lay a Solid Foundation of Battery Exchange Network Development',
  ah4_23: 'Shared Power Bank for Electric Vehicles Meeting the Pressing Needs of Takeout Delivery',
  ah4_24: 'China Has Cumulatively Exported More than 1 Billion Bicycles',
  ah4_25: 'Immotor Favored in the Jiangsu Electric Bicycle Fair, Introducing New Form of Intra-City Commute',
  ah4_26: 'Inside Story: Explore the Secret Behind Immotor’s Success in Winning Dual Honors',
  ah4_27: 'CES Asia 2019: Immotor Makes its Second Appearance and Reaches Strategic Cooperation with Hyundai',
  ah4_28: 'Steering the Wheel of New Energy industry, Immotor Reaches Strategic Cooperation with Hyundai',
  ah4_29: 'Transforming the Battery Charging into Battery Exchange of Electric Two-wheel Vehicles, Immotor Facilitates the Optimization of Commuting Ecosystem',
  ah4_30: 'Battery Exchange Highlighted in the chinacycle, Immotor Gained a Head-start ',
  ah4_31: 'New Immotor Products Released, Green Energy Network Expected to Prevail',
  ah4_32: 'Business Opportunities Harbored in Rechargeable Battery of Electric Two-wheel Vehicles, Immotor Secures Series B Financing up to 300 Million Yuan',
  ah4_33: 'Immotor Thrives against the Capital Winter',
  ah4_34: 'From Super Battery to Intelligent Battery Exchange Network, Immotor Strategizes as a Chess Master Catering to Hundreds of Millions of Electric Two-wheel Vehicles Riders',
  ah4_35: 'Unveiling a New Era of Intelligent Commuting, Immotor Joins the Wuxi Electric Vehicle Show',
  ah4_36: 'Immotor At Chengdu International New Energy Vehicles & Electric Vehicles Exhibition, Exploring the Making of Shared Commuting of Electric Vehicle Industry',
  ah4_38: 'Operating in Beijing, Chengdu and Beyond, Immotor Empowers the Short-distance Intra-city Delivery Industry',
  ah4_39: 'CES2018: Battery Exchange Under Intelligent Sharing, Overcoming the Pain Points of Traditional Lead-acid Battery Use',
  ah4_40: 'Immotor Announces its Completion of Series A Financing, Released its New Brand E Battery Exchange',
  ah4_41: 'Aiming to Develop Cabled ‘Telsa’, Immotor Secures its Series A Financing and Launches New Products of Battery Exchange',
  ah4_42: 'An Ambition of Instant Battery Exchange in under 3 Seconds, An Exclusive Interview with Immotor CEO Daniel HUANG',
  //about-公司简介-正文段落
  ap_1: "Shenzhen Immotor Technology Limited is a national high-tech enterprise and a leading player of green energy network for electric two-wheel vehicles. Founded in 2015, it released the Immotor brand in December 2017, and launched the world's first Intelligent Green Energy Platform integrating super batteries, intelligent battery exchange stations, energy management system, APP and big data platform.",
  ap_2: 'Keen on driving scientific and technological innovation and optimizing customer experience, Immotor is committed to harnessing the combined strength of cutting-edge battery technology and AI big data analytics to serve the mass market of China’s Electric Two-wheel Vehicles consumers. With a business presence in more than 60 cities nationwide, Immotor registers a daily volume of battery exchange orders of over 600,000, reinforcing its position as a global leader in the battery exchange market, and a pioneering innovator of intelligent green energy network.',
  ap_3: "Ranked among China's TOP 5 Best New Energy and Clean-Tech Investments in 2020, Immotor was listed into the Hurun China Future Unicorns 2019 and Hurun China Mountain Goats 2019,2020.",
  //about-发展历程-正文段落
  np2_1: 'In December, founded',
  np2_2: 'In November, announced the completion of Series Pre-A Financing',
  np2_3: 'In July, the first battery successfully developed',
  np2_4: 'In August, the first battery exchange station successfully developed',
  np2_5: 'In December, launched the Immotor brand and Super Battery M6, and announced the closing of Series A Financing',
  np2_6: 'In July, announced the completion of Series A+ Financing',
  np2_7: 'In December, released the Mini Battery Exchange Station and Super Battery M4, and announced the completion of Series B Financing',
  np2_8: 'In May, announced the completion of Series B+ Financing',
  np2_9: 'In September, announced the completion of Series C1 Financing',
  np2_10: 'In December, released the Super Series Battery and Battery Exchange Station and launched the Immotor brand',
  //about-我们努力践行的文化-正文段落
  np3_1: 'A global industry leader in intelligent energy network infrastructure',
  np3_2: 'Eyes on the Future\nWork to Lead',
  np3_3: 'More forward-looking technologies\nMore pragmatic thinking\nMore efficient operations\nA better vision',
  //about-按钮
  btn_3: 'Read More',
  btn_4: 'Previous',
  btn_5: 'Next',






  //新增视频
  v1_h1: "Dual Fire Prevention System   Safety Guarantee",
  v1_p1: "这里是内容这里是内容这里是内容这里是内容",
  v2_h1: 'Quality Assurance  Intelligent Manufacturing',
  //新增-海外招商
  n_h1: 'Essentials',
  n_h2: 'Professional',
  n_h3: 'Unlimited',
  n_p1: 'Minimally Adequate. No More, No Less.\nEverything that is required for easy & fast\nroll-out of your battery exchange business',
  n_p2: 'The full product suite to get your battery\nexchange business off the ground',
  n_p3: 'The privilege of unrivalled speed &\nease of implementation, use, upgrade\nand scaling in growing your business',
  n_ul1_li: 'IMMOTOR Operations & Maintenance Platform & APP',
  n_ul2_li1: 'Operations & Maintenance Platform & APP',
  n_ul2_li2: 'IMMOTOR Battery Exchange User APP',
  n_ul2_li3: 'Business Operations Platform',
  n_ul2_li4: 'BI Bashboard',
  n_ul3_li1: 'IMMOTOR Battery Exchange API',
  n_ul3_li2: 'Operations & Maintenance API',
  n_ul3_li3: 'Data API',
  n_btn: 'FEATURES',
  n_type_1: 'Battery Exchange',
  n_type_2: 'Device Management & Maintenance',
  n_type_3: 'Data Dashboard & Monitoring',
  n_type_4: 'Business & User Operations',
  n_type_5: 'Increased Availability, Performance, Scalability',
  n_type_6: 'Dashboard & Reporting',
  n_btn_2: 'TRY NOW',



  n_ah4_1: 'Immotor Technology Completes Series C2 Financing, and will speedup the globalization of  battery swapping services',
  n_ah4_2: 'Yinchuan Urban Construction Investment Holding Co., Ltd and Qingdao IMMOTOR  had singed the agreement of green energy system construction project for small vehicles',
  n_ah4_3: 'Shenzhen Immotor Technology is selected on the list of "specialization and special new" small and medium-sized enterprises 2021 of Shenzhen',
  n_ah4_4: 'Hyperchain Technology together with Immotor Technology launch the world’s first battery swapping Saas platform with Blockchain technology',
  n_ah4_5: 'TencentCloud future community together with Immotor Technology are going to build a green and safe community',
  n_ah4_6: 'Immotor Technology is awarded as the [2021 Real 100 Innovators] of The first Jiemian technology conference',
  n_nt_1: '2022-06-28',
  n_nt_2: '2022-06-23',
  n_nt_3: '2022-06-15',
  n_nt_4: '2022-06-14',
  n_nt_5: '2021-12-10',
  n_nt_6: '2021-10-14',
};



