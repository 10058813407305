import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '',
    component: resolve => require(['@/views/Home.vue'], resolve),
    meta:{}
  },
  {
    path: '/about',
    name: 'About',
    component: resolve => require(['@/views/About.vue'], resolve)
  },
  {
    path: '/join',
    name: 'Join',
    component: resolve => require(['@/views/Join.vue'], resolve)
  },
  {
    path: '/soft',
    name: 'Soft',
    component: resolve => require(['@/views/Soft.vue'], resolve)
  },
  {
    path: '/product',
    name: 'Product',
    component: resolve => require(['@/views/Product.vue'], resolve)
  },
];

const router = new VueRouter({
  routes:routes,
  mode:'history'

});



export default router;
