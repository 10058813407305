<template>
  <div class="footer pdbt font-medium" :class="isEn?'footer-en':''">
    <div class="container">
      <div class="left">
        <img src="../assets/logo/foot-logo.png"  />
        <p class="company fz14">{{ $t('company') }}</p>
      </div>
      <div class="right">
        <div class="container-dl">
          <dl 
          v-for="item in footMenu" 
          class="left"
          :key="item.mainIndex"
          >
            <dt>{{$t(item.name)}}</dt>
            <dd  
                v-for="sub in item.sub"
                :key="sub.name"
                @click="doJump(sub)" 
                @mouseover="mouseOver(item.mainIndex,sub.subIndex)" 
                @mouseleave="mouseLeave(item.mainIndex,sub.subIndex)" 
                :class="{active:sub.clickSign||sub.inSign}"
                > 
                {{$t(sub.name)}}
            </dd> 
          </dl>
        </div>
      </div>
      <div class="code">
        <h2 class="fw700 fz24"> {{$t('phone')}}</h2>
        <div>
          <img src="../assets/about/qrcode.png" :alt="$t('wechat')" />
          <p class="company fz14">{{ $t('wechat') }}</p>
        </div>
      </div>
    </div>
    <div class="filings">
      <a target="_blank" href="https://beian.miit.gov.cn/" class="fz12 fw400 font-regular">© e-swap.cn 版权所有 ICP证：粤ICP备2023131767号-4</a>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    isEn:function(){
      return this.$store.state.isEn
    }
  },
  watch: {},
  name: 'eFooter',
  data() {
    return {
      footMenu:[
        {
          name:'nav_2',
          mainIndex:0,
          sub:[
            {
              path:'/product',
              name:'nav_2_1',
              clickSign:false,
              inSign:false,
              scroll:700,
              subIndex:0,             
            },{
              path:'/product',
              name:'nav_2_2',
              clickSign:false,
              inSign:false,
              scroll:1590,
              subIndex:1,             
            },{
              path:'/product',
              name:'nav_2_3',
              clickSign:false,
              inSign:false, 
              scroll:3286,
              subIndex:2,            
            }
          ],      
        },
        {
          name:'nav_3',
          mainIndex:1,
          sub:[
            {
              path:'/soft',
              name:'nav_3_1',
              clickSign:false,
              inSign:false,
              scroll:1650,
              subIndex:0,             
            },{
              path:'/soft',
              name:'nav_3_2',
              clickSign:false,
              inSign:false,
              scroll:2840, 
              subIndex:1,            
            },{
              path:'/soft',
              name:'nav_3_3',
              clickSign:false,
              inSign:false,
              scroll:3660,
              subIndex:2,             
            }
          ],      
        },
        {
          name:'nav_4',
          mainIndex:2,
          sub:[
            {
              path:'/about',
              name:'nav_4_1',
              clickSign:false,
              inSign:false,
              scroll:0,
              subIndex:0,             
            },
            {
              path:'/about',
              name:'nav_4_2',
              clickSign:false,
              inSign:false,
              scroll:800,
              subIndex:1,             
            },
          ],      
        },
        {
          name:'nav_5',
          mainIndex:3,
          sub:[
            {
              path:'/join',
              name:'nav_5_1',
              clickSign:false,
              inSign:false,
              scroll:0,
              subIndex:0,            
            },
            {
              path:'/join',
              name:'nav_5_2',
              clickSign:false,
              inSign:false,
              scroll:500,
              subIndex:1,            
            },
          ],      
        }
      ],
      openBtn:false,
      n:0,
    };
  },
  methods: {
    doJump(sub){
      this.$nextTick(function() {
        this.toDoJump(sub);
      })
    },
    /**
     * 跳转页面
     * path: 需要跳转的路由
     * id: 锚点
     */
    toDoJump(sub) {
      // 跳转到非当前路由页面，需要先进行路由跳转
      const fullPath = this.$route.fullPath;
      if (sub.path !== fullPath) {
        this.$router.push({
          path: sub.path
        });   
      }
      window.scrollTo(0,sub.scroll); 
    },
    // 子-移入
    mouseOver(m,n) {
      this.footMenu[m].sub[n].inSign = true
    },
    // 子-移出
    mouseLeave(m,n) {  
      this.footMenu[m].sub[n].inSign = false 
    }, 
  }
};
</script>

<style lang="scss" scoped>
.btn{
  color: #999999;
}
.footer {
  height: 603px;
  box-sizing: border-box;
  position: relative;
  background-color: #f8f9fb;
  .container {
    padding-top: 64px;
    width: 1200px;
    border-top: 1px solid #cfd3da;
    display: flex;
    text-align: left;
    position: relative;
    .left {
      img{
        width:124px;
        display: inline-block;
        margin: 0;
        margin-bottom: 16px;
      }
      .company{
        color: #393f47;
        line-height: 22px;
        letter-spacing: 1px;      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: center;
      -moz-user-select:none; /*火狐*/
      -webkit-user-select:none; /*webkit浏览器*/
      -ms-user-select:none; /*IE10*/
      user-select:none;
      .container-dl{
        width: 436px;
        display: flex;
        dl {
          flex: 1;
          text-align: center;
          dd {
            cursor: pointer;
            &.active{
              color: rgba(22,25,60,1);
            }
          }
        }
      }
      
    }
    .code{
      color: #181d24;
      img{
        width:112px;
        display: inline-block;
        margin: 18px 0 8px 0;
      }
      p{
        width: 112px;
        text-align: center;
        line-height: 18px;
      }
    }
  }
  .filings{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 100px;
    line-height: 20px;
    a{
      color: #788496;
    }
    img{
      width: 20px;
      margin: 0 4px 0 12px;
    }
  }
}
@media (max-width: 1280px) {
  .footer-en .container{
    width: 70%;
  }
}
@media (max-width: 996px) {
  .footer {
    .container {
      display: block;
      .left {
        width: 100%;
        text-align: center;
        margin-bottom: 80px;
        p {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .footer {
    .container {
      width: 100%;
      .left {
        padding: 0 15px;
        p {
          word-break: normal;
        }       
      }
      .right {
        flex-direction: column;
        padding: 0 15px;
        dl {
          display: block;
          margin-bottom: 20px;
          overflow: hidden;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          dt {
            width: 20%;
            float: left;
          }
          dd {
            width: 20%;
            float: left;
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
